import { Button, Dropdown, Spinner, Text } from "components";
import { errorNotification, successNotification } from "utils/notification";
import { rAccount, rConfirmationModalData, rUser } from "utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import AdminWrapper from "components/AdminWrapper";
import { apiRequest } from "utils/apiRequests";
import colors from "utils/colors";
import { get } from "lodash";
import { getAccountTrialDays } from "utils/utils";
import styled from "styled-components";
import useMixpanel from "utils/useMixpanel";
import { useNavigate } from "react-router-dom";

const Flows = () => {
  const user = useRecoilValue(rUser);
  const [account, setAccount] = useRecoilState(rAccount);
  const [flows, setFlows] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const billing = get(account, "billing", {});

  const trialDaysRemaining = getAccountTrialDays(account);

  const hasPayments = get(billing, "has_payments", false);

  const subscription = get(billing, "subscription", null);

  const flowsCount = get(billing, "flows_count", 0);
  const flowsLimit = get(subscription, "flows", 1);

  const navigate = useNavigate();

  // Get flows
  const getFlows = async () => {
    setIsFetching(true);
    const flowsRes = await apiRequest.get("/flows/");
    setFlows(get(flowsRes, "data", []));
    setIsFetching(false);
  };

  useEffect(() => {
    if (user && flows.length === 0) {
      getFlows();
    }
  }, [user]);

  const createNewFlow = async () => {
    if (trialDaysRemaining <= 0 && !hasPayments && !subscription) {
      navigate("/admin/billing");
      errorNotification(
        "Your trial has ended. To continue using Curator, upgrade your plan"
      );
      return;
    }

    if (flowsCount >= flowsLimit) {
      navigate("/admin/billing");
      errorNotification("To add more flows, upgrade your plan");
      return;
    }

    setIsCreating(true);
    const flowRes = await apiRequest.post("/create_flow/");
    const newFlowId = get(flowRes, ["data", "id"]);
    setIsCreating(false);
    navigate(`/admin/flow/${newFlowId}`);
    setAccount({
      ...account,
      billing: {
        ...account.billing,
        flows_count: account.billing.flows_count + 1,
      },
    });
  };

  const deleteFlow = (id) => {
    setConfirmationModalData({
      title: "Delete Flow",
      text: "Are you sure you want to delete this flow?",
      confirmText: "Delete",
      cancelText: "Cancel",
      isFetching: isFetching,
      confirm: () => {
        setIsFetching(true);
        apiRequest.delete("/flow/", { data: { id } }).then(() => {
          successNotification("Flow deleted");
          navigate("/admin/flows");
          setIsFetching(false);
          setFlows(flows.filter((s) => s.id !== id));
        });
      },
    });
  };

  // Track mixpanel
  const { track } = useMixpanel();
  useEffect(() => {
    track("Flows List");
  }, []);

  const platform = get(account, "automation_platform", null);

  const videoUrlMap = {
    Zapier: "https://youtu.be/_duQ1S9fh08",
    Make: "https://www.youtube.com/watch?v=9j8b0DCw_Jc",
  };

  const videoUrl = get(
    videoUrlMap,
    platform,
    "https://www.youtube.com/watch?v=9j8b0DCw_Jc"
  );

  return (
    <AdminWrapper
      title="Approval Flows"
      description="Define approval flows for specific use-cases"
      buttons={[
        {
          text: "Create Flow",
          onClick: createNewFlow,
          size: "large",
          icon: "FiPlus",
          type: "primary",
          isFetching: isCreating,
        },
      ]}
    >
      <Container>
        {isFetching && <Spinner color={colors.primary} />}
        {!isFetching && (
          <>
            <List
              items={flows.map((p) => (
                <Flow data={p} onDelete={() => deleteFlow(p.id)} />
              ))}
              emptyText="You haven't created any flows yet. Click 'Create Workflow' to create your first flow."
            />
            {flows.length === 0 && (
              <LearnContainer>
                <Text
                  data={{
                    text: "Learn how to use Curator",
                    fontSize: 24,
                    fontWeight: 600,
                  }}
                />
                <Button
                  data={{
                    text: "Watch Tutorial (7 min)",
                    icon: "FiVideo",
                    size: "large",
                    onClick: () => window.open(videoUrl, "_blank"),
                  }}
                />
              </LearnContainer>
            )}
          </>
        )}
      </Container>
    </AdminWrapper>
  );
};

export default Flows;

const LearnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.inputBorder};
  border-radius: 10px;
  padding: 40px;
  background: white;
  gap: 20px;
  margin-top: 10px;
`;

const Container = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 100%;
`;

const FlowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  cursor: pointer;
  background: white;
  padding: 20px;
`;

const Flow = ({ data, onDelete }) => {
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);

  const duplicateFlow = async () => {
    setIsFetching(true);
    const res = await apiRequest.post("/duplicate_flow/", { id: data.id });
    const newFlow = get(res, "data", {});
    setIsFetching(false);
    successNotification("Flow duplicated");
    navigate(`/admin/flow/${newFlow.id}`);
  };

  if (isFetching) {
    return (
      <FlowContainer>
        <Spinner color={colors.primary} />
      </FlowContainer>
    );
  }

  return (
    <FlowContainer onClick={() => navigate(`/admin/flow/${data.id}`)}>
      <div>
        <Text
          data={{
            text: data.name,
            fontSize: 20,
            fontWeight: 700,
            cursor: "pointer",
            margin: "0 0 10px 0",
          }}
        />
        <Text
          data={{
            text: `${data.session_count} approvals`,
            size: 14,
            color: "var(--light-grey)",
            cursor: "pointer",
          }}
        />
      </div>
      <Dropdown
        data={{
          icon: {
            icon: "FiMoreHorizontal",
            size: 25,
            color: colors.grey,
            hover: true,
          },
          options: [
            {
              label: "Delete",
              icon: "FiTrash",
              onClick: onDelete,
            },
            {
              label: "Duplicate",
              icon: "FiCopy",
              onClick: duplicateFlow,
            },
          ],
        }}
      />
    </FlowContainer>
  );
};

export const List = ({ items = [], emptyText = "No Items" }) => {
  if (items.length === 0) {
    return <Text data={{ text: emptyText, fontSize: 20 }} />;
  }

  return (
    <ListContainer itemCount={items.length}>
      {items.map((item, i) => (
        <ItemContainer key={i}>{item}</ItemContainer>
      ))}
    </ListContainer>
  );
};

const ListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;

  ${(p) =>
    p.itemCount === 1 &&
    `
    grid-template-columns: 300px;
    `}

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ItemContainer = styled.div`
  border: 1px solid ${colors.inputBorder};
  cursor: pointer;
  border-radius: 10px;
  background: white;
  overflow: hidden;
`;
