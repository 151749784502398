import { Button, Hint, Row, Text } from "components";
import { get, startCase } from "lodash";
import { getPixels, getUniqueValues } from "utils/utils";

import FormField from "components/FormField";
import styled from "styled-components";

const Form = ({
  value,
  fields,
  onChange,
  editField, // for staticfields only
  submit,
  submitText,
  inputStyles = {
    fontSize: 14,
    padding: "10px",
  },
  sectionPadding,
  firstSectionPadding, // for overriding the first section only - this is hacky
  isFetching,
  sectionOrder = [],
  errors,
  labelFontSize,
  labelFontWeight,
  buttonColor,
  buttonFullWidth,
  borderBottom = null,
  margin = "0px",
  disableSubmit,
  gridLayout = false,
  gridLayoutSize = null,
  hideSectionLabel = false,
  buttons = [],
  otherContent = null,
  submitOnEnter = false,
}) => {
  const buttonBorderRadius = null;

  const sections = sortSections(
    getUniqueValues(fields, "section", false),
    sectionOrder
  );

  const hideBorder = sections.length === 1;

  const getComponent = (componentId, disabled) => {
    if (
      !disabled ||
      ["Switch", "Checkbox", "ImageUpload", "TextArea"].includes(componentId)
    ) {
      return componentId;
    }

    if (!["Switch", "Checkbox", "ImageUpload"].includes(componentId)) {
      return "Input";
    }
  };

  fields = fields
    .filter((f) => f.type !== "Hidden")
    .filter((f) => f.componentId !== "Hidden")
    .map((f) => ({
      ...f,
      componentId: getComponent(f.componentId, f.disabled),
      value: f.value || get(value, f.id || f.key),
    }));

  const gridRowGap = 20;
  const gridColumnGap = 20;

  return (
    <div style={{ margin, width: "100%" }}>
      {sections.map((s, index) => {
        const sectionFields = fields.filter((f) => f.section === s);

        const formSectionPadding =
          index === 0 ? firstSectionPadding || sectionPadding : sectionPadding;

        const sectionHint = sectionFields.find((f) => f.sectionHint);

        return (
          <FormSection
            key={index}
            label={startCase(s)}
            hint={sectionHint && sectionHint.sectionHint}
            borderBottom={borderBottom && !hideBorder}
            sectionPadding={formSectionPadding}
            hideSectionLabel={hideSectionLabel}
            gridLayout={gridLayout}
            gridLayoutSize={gridLayoutSize}
            gridRowGap={getPixels(gridRowGap)}
            gridColumnGap={getPixels(gridColumnGap)}
          >
            {sectionFields.map((field, i) => {
              let fieldOnChange = get(field, "onChange") || onChange;

              const finalInputStyle = field.formText ? {} : inputStyles;

              return (
                <FormField
                  key={field.id}
                  data={{
                    ...field,
                    ...finalInputStyle,
                    label: get(field, "label", startCase(field.id)),
                    componentId: get(field, "componentId", "Input"),
                    editField,
                    labelFontSize,
                    labelFontWeight,
                    error: get(errors, field.id),
                    onChange: (value) =>
                      fieldOnChange(field.id || field.key, value),
                    onKeyPress: (e) => {
                      if (submitOnEnter) {
                        if (e.key === "Enter") {
                          submit();
                        }
                      }
                    },
                  }}
                />
              );
            })}
          </FormSection>
        );
      })}
      {otherContent}

      {(submit || buttons.length > 0) && (
        <Row $alignitems="center" $gap="15px" $margin="20px 0 0 0">
          {submit && (
            <Button
              data={{
                backgroundColor: buttonColor,
                onClick: submit,
                width: buttonFullWidth && "100%",
                isFetching,
                borderRadius: buttonBorderRadius,
                text: submitText || "Submit",
                disabled: disableSubmit,
                fontSize: 14,
                fontWeight: 600,
              }}
            />
          )}
          {buttons &&
            buttons.map((b) => (
              <Button
                data={{
                  ...b,
                  borderRadius: buttonBorderRadius
                    ? getPixels(buttonBorderRadius)
                    : null,
                }}
              />
            ))}
        </Row>
      )}
    </div>
  );
};

export default Form;

const Fields = styled.div`
  border-bottom: ${(p) => (p.borderBottom ? "1px" : "0px")} solid var(--divider);
  padding: ${(p) => p.sectionPadding || "0px"};
  display: flex;
  flex-direction: column;
  gap: ${(p) => getPixels(p.$gap || 15)};
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: ${(p) => p.gridRowGap};
  grid-column-gap: ${(p) => p.gridColumnGap};
  ${(p) =>
    p.gridLayout &&
    `
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(${
      p.gridLayoutSize || "250px"
    }, 1fr));
  `}
`;

export const FormSection = ({
  label,
  hint,
  borderBottom,
  children,
  sectionPadding,
  hideSectionLabel = false,
  gridLayout = false,
  gridLayoutSize = null,
  gridColumnGap,
  gridRowGap,
}) => {
  return (
    <Fields
      borderBottom={borderBottom}
      sectionPadding={sectionPadding}
      $gap={hint ? 5 : 15}
    >
      {!hideSectionLabel && label && (
        <Row $alignitems="center" $gap="5px">
          <Text
            data={{
              text: label,
              fontSize: 16,
              fontWeight: 600,
            }}
          />
          {hint && <Hint hint={hint} margin="5px 0 0 0" />}
        </Row>
      )}
      <Items
        gridLayout={gridLayout}
        gridLayoutSize={gridLayoutSize}
        gridColumnGap={gridColumnGap}
        gridRowGap={gridRowGap}
      >
        {children}
      </Items>
    </Fields>
  );
};

const sortSections = (sections, sectionOrder) => {
  const orderedSections = sections
    .filter((section) => sectionOrder.includes(section))
    .sort((a, b) => sectionOrder.indexOf(a) - sectionOrder.indexOf(b));

  const undefinedSections = sections.filter(
    (section) => !sectionOrder.includes(section)
  );

  return orderedSections.concat(undefinedSections);
};
