import colors from "utils/colors";
import curatorLogoWhite from "images/curator-white.png";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";

const Container = styled.div`
  background: ${colors.primary};
  border-radius: 5px;
  padding: 10px;
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 9999;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 7px;
  @media (max-width: 800px) {
    bottom: 5px;
    left: 5px;
    padding: 5px;
  }
`;

const Logo = styled.img`
  height: 18px;
  @media (max-width: 800px) {
    height: 14px;
  }
`;

const MadeWithText = styled.div`
  font-size: 16px;
  color: white;
  @media (max-width: 800px) {
    font-size: 13px;
  }
`;

const MadeWith = () => {
  return (
    <Container
      onClick={() => {
        window.open("https://curatorapp.ai?ref=madewithbadge");
        mixpanel.track("Made With Curator Badge");
      }}
    >
      <MadeWithText>Made with</MadeWithText>
      <Logo src={curatorLogoWhite} />
    </Container>
  );
};

export default MadeWith;
