import { rAccount, rUser } from "utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { Button } from "components";
import { apiRequest } from "utils/apiRequests";
import { errorNotification } from "utils/notification";
import { get } from "lodash";
import logo from "images/curator-green.png";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Onboarding = () => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [onboardingData, setOnboardingData] = useState({});

  const companyName = get(onboardingData, "name", "");
  const website = get(onboardingData, "website", "");
  const platform = get(onboardingData, "platform", "");

  const user = useRecoilValue(rUser);

  const [account, setAccount] = useRecoilState(rAccount);

  const name = get(user, "first_name", "");

  const steps = [
    {
      key: "name",
      question: `Hi ${name}! What's your company name?`,
      placeholder: "Company Name",
    },
    {
      key: "website",
      question: `Hi ${name}! What's your company website?`,
      placeholder: "yourcompany.com",
    },
    {
      key: "platform",
      question: "What's your primary automation platform?",
      options: [
        {
          label: "Zapier",
          image:
            "https://res.cloudinary.com/curatorapp/image/upload/v1734630593/zapier-logo_zuwhnx.png",
        },
        {
          label: "Make",
          image:
            "https://res.cloudinary.com/curatorapp/image/upload/v1734630616/make-logo_mefsuc.png",
        },
        {
          label: "N8n",
          image:
            "https://res.cloudinary.com/curatorapp/image/upload/v1734630593/n8n-logo_dght3n.png",
        },
        {
          label: "ActivePieces",
          image:
            "https://res.cloudinary.com/curatorapp/image/upload/v1736460723/activepieces-logo-hz-og_ecsjlt.svg",
        },
        {
          label: "LateNode",
          image:
            "https://res.cloudinary.com/curatorapp/image/upload/v1736460898/latenode_mdhi13.png",
        },
        {
          label: "Albato",
          image:
            "https://res.cloudinary.com/curatorapp/image/upload/v1736461084/albato1_yjv0zi.png",
        },
      ],
    },
  ];

  const currentStep = steps[currentStepIndex];

  const currentQuestion = currentStep.question;
  const currentStepValue = onboardingData[currentStep.key];

  const [isFetching, setIsFetching] = useState(false);

  const navigate = useNavigate();

  // Handlers to update state
  const onChange = (value) => {
    setOnboardingData({ ...onboardingData, [currentStep.key]: value });
  };

  const handleNext = async () => {
    if (currentStepIndex === steps.length - 1) {
      setIsFetching(true);

      await apiRequest.post("/onboarding/", {
        name: companyName,
        automation_platform: platform,
        website,
      });

      setIsFetching(false);

      setAccount({
        ...account,
        organization_name: name,
        name,
        automation_platform: platform,
        website,
      });

      navigate("/admin/flows");
    } else {
      // Apply validation
      if (currentStep.key === "website" && !website) {
        errorNotification("Please enter your company name");
        return;
      }

      if (currentStep.key === "platform" && !platform) {
        errorNotification("Please make a valid selection");
        return;
      }

      setCurrentStepIndex(currentStepIndex + 1);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleNext();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentStepIndex, onboardingData]);

  return (
    <Container>
      <Logo src={logo} />

      <Content>
        <Question>{currentQuestion}</Question>
        <RenderInput
          key={currentStep.key}
          step={currentStep}
          onChange={onChange}
          value={currentStepValue}
        />
      </Content>

      <ButtonRow>
        <div></div>
        <Button
          data={{
            text: "Continue",
            icon: "FiArrowRight",
            backgroundColor: "#292929",
            size: "large",
            flippedIcon: true,
            onClick: handleNext,
            isFetching,
          }}
        />
      </ButtonRow>
    </Container>
  );
};

export default Onboarding;

const RenderInput = ({ step, onChange, value }) => {
  switch (step.key) {
    case "platform":
      const options = get(step, "options", []);

      const otherValue = options.includes(value) ? "" : value;

      return (
        <OptionsContainer>
          {options.map((option) => (
            <Option
              isImage={option.image}
              key={option}
              active={value === option.label}
              onClick={() => onChange(option.label)}
            >
              {option.image ? (
                <PlatformLogo src={option.image} />
              ) : (
                option.label
              )}
            </Option>
          ))}
          <OtherInput
            value={otherValue}
            onChange={(e) => onChange(e.target.value)}
            placeholder="Other (type here)"
          />
        </OptionsContainer>
      );
    default:
      let fontSize = "80px";
      if (get(value, "length", 0) > 30) {
        fontSize = "60px";
      }
      if (get(value, "length", 0) > 45) {
        fontSize = "40px";
      }
      return (
        <StyledTextArea
          fontSize={fontSize}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={step.placeholder}
          autoFocus
        />
      );
  }
};

const PlatformLogo = styled.img`
  height: 45px;
  width: 130px;
  object-fit: contain;
  cursor: pointer;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 30px;
  max-width: 800px;
`;

const Option = styled.div`
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #292929;
  width: fit-content;
  border: 1px solid #292929;
  border-radius: 30px;
  height: 60px;
  padding: ${(p) => (p.isImage ? "13px" : "18px")};
  display: flex;
  align-items: center;
  justify-content: center;
  outline: ${(p) => (p.active ? "4px solid #292929" : "none")};
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  overflow: hidden;
`;

const OtherInput = styled.input`
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #292929;
  border: 1px solid #292929;
  border-radius: 30px;
  padding: 15px;
  height: 60px;
`;

// Styled-components
const Container = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  background: white;
  padding: 40px;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 80%;
  margin-left: 100px;
  @media screen and (max-width: 800px) {
    margin-left: 0px;
    width: 90%;
  }
`;

const Logo = styled.img`
  height: 40px;
`;

const Question = styled.div`
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  font-family: "Inter", sans-serif;
  font-size: ${(p) => p.fontSize || "80px"};
  @media screen and (max-width: 800px) {
    font-size: 40px;
  }
  padding: 0px;
  border: 0px;
  margin-top: 10px;
  outline: none;
  background-color: transparent;
  width: 100%;
  resize: none;
  ::placeholder {
    color: rgba(41, 41, 41, 0.38);
  }
`;
