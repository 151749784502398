import { Hint, Row, Text } from "components";

import styled from "styled-components";

const FormInputWrapper = ({
  label,
  labelFontSize,
  labelFontWeight,
  children,
  hint,
  error,
  description,
  margin,
  link,
  orientation = "vertical",
  labelWidth = "auto",
  columnSpan = 1,
  isAdmin,
  handleFieldClick,
}) => {
  return (
    <Container
      $columnspan={columnSpan}
      onClick={(e) => {
        if (isAdmin && handleFieldClick) {
          handleFieldClick(e.currentTarget);
        }
      }}
      handleFieldClick={isAdmin && handleFieldClick}
      $margin={margin}
    >
      <Wrapper flexDirection={orientation === "vertical" ? "column" : "row"}>
        {label && (
          <Row $alignitems="center" $gap="5px" width={labelWidth}>
            <Text
              data={{
                text: label,
                margin: "0 0 5px 0",
                fontSize: labelFontSize || 14,
                fontWeight: labelFontWeight || 500,
                color: "var(--text-color)",
              }}
            />
            {hint && <Hint hint={hint} link={link} />}
          </Row>
        )}
        {description && (
          <Text
            data={{
              text: description,
              margin: "0 0 5px 0",
              fontSize: 12,
              color: "var(--light-grey)",
            }}
          />
        )}
        {children}
      </Wrapper>
      {error && (
        <Text
          data={{
            text: error,
            margin: "2px 0 0 0",
            color: "red",
            fontSize: 12,
          }}
        />
      )}
    </Container>
  );
};

export default FormInputWrapper;

const Container = styled.div`
  margin: ${(p) => p.$margin};

  grid-column: span ${(p) => p.$columnspan};

  @media screen and (max-width: 800px) {
    grid-column: span 1;
  }

  ${(p) =>
    p.handleFieldClick &&
    `
    &:hover{
      outline: 1px solid blue;
      outline-offset: -2px;
      cursor: pointer;
      border-radius: 4px;
    }
`};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${(p) => p.flexDirection || "column"};
  gap: 0px;
  ${(p) =>
    p.flexDirection === "row" &&
    "justify-content: space-between; align-items: center; gap: 20px;"}
`;
