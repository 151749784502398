import { Icon, Row, Select, Spinner, Table, Text } from "components";
import { get, isEmpty } from "lodash";
import { rAccount, rUser } from "utils/recoil";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AdminWrapper from "components/AdminWrapper";
import LiveWrapper from "pages/Sessions/Session/LiveWrapper";
import { apiRequest } from "utils/apiRequests";
import { getUrlParameter } from "utils/utils";
import styled from "styled-components";
import useMixpanel from "utils/useMixpanel";
import { useRecoilValue } from "recoil";

const Approvals = ({ adminMode = false }) => {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sessionsFetched, setSessionsFetched] = useState(false);

  const [showFilters, setShowFilters] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  // Pagination
  const [pageCount, setPageCount] = useState(1);

  const account = useRecoilValue(rAccount);
  const user = useRecoilValue(rUser);
  const isAdmin = get(user, "admin", false);

  const { track } = useMixpanel();
  useEffect(() => {
    track("Sessions Dashboard");
  }, []);

  const updateUrlParams = (params) => {
    const searchParams = new URLSearchParams(location.search);
    Object.keys(params).forEach((key) => {
      searchParams.set(key, params[key]);
    });
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const flagTypeSetting = getUrlParameter("flagType", location) || "flagged";

  const fetchSessions = ({
    type = getUrlParameter("type", location) || "all_approvals",
    flagType = flagTypeSetting,
    completionStatus = getUrlParameter("completionStatus", location) ||
      "pending",
    page = getUrlParameter("page", location) || 1,
  } = {}) => {
    setLoading(true);
    setShowFilters(false);
    apiRequest
      .get(
        `/sessions/?type=${type}&flag_type=${flagType}&completion_status=${completionStatus}&page=${page}`
      )
      .then((res) => {
        const sessions = get(res, "data.sessions", []);
        const pageCount = get(res, "data.page_count", 1);

        setSessions(sessions);
        setPageCount(pageCount);

        setLoading(false);
        setSessionsFetched(true);
      });
  };

  useEffect(() => {
    if (!isEmpty(account) && !sessionsFetched) {
      fetchSessions();
    }
  }, [account]);

  const updateParameter = (key, value) => {
    updateUrlParams({ [key]: value });
    fetchSessions({ [key]: value });
  };

  const hasTags = sessions.some((s) => !isEmpty(s.tags));

  let columns = [
    {
      id: "title",
      fontWeight: 400,
      flex: 2,
    },
    {
      id: "created_date",
      flex: 1,
    },
    {
      id: "status",
      label: "Status",
      flex: 1,
    },
  ];

  if (hasTags) {
    columns = [
      ...columns,
      {
        id: "tags",
        type: "badge",
        flex: 1,
      },
    ];
  }

  if (isAdmin && flagTypeSetting === "all") {
    columns = [
      ...columns,
      {
        id: "flagged",
        type: "boolean",
        flex: 1,
      },
    ];
  }

  const filters = (
    <FiltersRow>
      <FilterWrapper>
        <Select
          data={{
            value: getUrlParameter("type", location) || "all_approvals",
            options: [
              { label: "All Approvals", value: "all_approvals" },
              { label: "My Approvals", value: "my_approvals" },
            ],
            onChange: (value) => updateParameter("type", value),
          }}
        />
      </FilterWrapper>

      <FilterWrapper>
        <Select
          data={{
            value: getUrlParameter("completionStatus", location) || "pending",
            options: [
              {
                label: "All",
                value: "all",
              },
              { label: "Pending", value: "pending" },
              { label: "Completed", value: "completed" },
            ],
            onChange: (value) => updateParameter("completionStatus", value),
          }}
        />
      </FilterWrapper>

      {isAdmin && (
        <FilterWrapper>
          <Select
            data={{
              value: getUrlParameter("flagType", location) || "flagged",
              options: [
                { label: "All", value: "all" },
                { label: "Flagged", value: "flagged" },
                { label: "Unflagged", value: "unflagged" },
              ],
              onChange: (value) => updateParameter("flagType", value),
            }}
          />
        </FilterWrapper>
      )}
    </FiltersRow>
  );

  const Wrapper = adminMode ? AdminWrapper : LiveWrapper;

  const sessionPath = adminMode ? "/admin/session" : "/session";

  return (
    <Wrapper session={{ account }}>
      <DashboardWrapper>
        <HeaderRow>
          <ApprovalsText>Approvals</ApprovalsText>

          {showFilters && (
            <MobileFiltersPopup>
              <Row
                $alignitems="center"
                $gap="15px"
                $justifycontent="space-between"
                $margin="0 0 20px 0"
              >
                <Text
                  data={{
                    text: "Apply Filters",
                    fontWeight: 700,
                    fontSize: 22,
                  }}
                />
                <Icon
                  data={{
                    icon: "FiX",
                    size: 30,
                    color: "rgba(0, 0, 0, 0.5)",
                    hover: true,
                    onClick: () => setShowFilters(false),
                  }}
                />
              </Row>
              {filters}
            </MobileFiltersPopup>
          )}

          <MobileFilters>
            <Icon
              data={{
                icon: "FiMenu",
                size: 26,
                color: "rgba(0, 0, 0, 0.5)",
                hover: true,
                onClick: () => setShowFilters(true),
              }}
            />
          </MobileFilters>

          <DesktopFilters>{filters}</DesktopFilters>
        </HeaderRow>

        {loading ? (
          <Spinner />
        ) : (
          <>
            {sessions.length === 0 && (
              <Text
                data={{
                  text: "You have no pending approvals at this time.",
                  fontSize: 20,
                }}
              />
            )}

            {sessions.length > 0 && (
              <Table
                data={{
                  pagination: {
                    page: parseInt(getUrlParameter("page", location) || 1),
                    pageCount,
                    onChange: (page) => updateParameter("page", page),
                  },
                  rows: sessions.map((s) => ({
                    ...s,
                    status: s.completed_date ? "Completed" : "Pending",
                    // actions: [
                    //   {
                    //     label: "Delete",
                    //     onClick: () => console.log("DELETE SESSION", s),
                    //   },
                    // ],
                  })),
                  columns,
                  onRowClick: (i) =>
                    navigate(`${sessionPath}/${get(sessions, [i, "uuid"])}`),
                }}
              />
            )}
          </>
        )}
      </DashboardWrapper>
    </Wrapper>
  );
};

export default Approvals;

const DashboardWrapper = styled.div`
  width: 100%;
`;

const ApprovalsText = styled.div`
  font-size: 30px;
  font-weight: 700;
  @media (max-width: 800px) {
    font-size: 22px;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 30px 0;
  gap: 15px;
  @media (max-width: 800px) {
    margin: 0 0 20px 0;
  }
`;

const FiltersRow = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const MobileFilters = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
`;

const DesktopFilters = styled.div`
  display: block;
  @media (max-width: 800px) {
    display: none;
  }
`;

const MobileFiltersPopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1000;
  padding: 20px;
`;

const FilterWrapper = styled.div`
  width: 150px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;
