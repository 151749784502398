import { Form, Modal, Spinner, Table } from "components";
import { errorNotification, successNotification } from "utils/notification";
import { get, truncate } from "lodash";
import { useEffect, useState } from "react";

import AdminWrapper from "components/AdminWrapper";
import { apiRequest } from "utils/apiRequests";
import { rConfirmationModalData } from "utils/recoil";
import styled from "styled-components";
import useMixpanel from "utils/useMixpanel";
import { useSetRecoilState } from "recoil";

const Context = () => {
  const [contexts, setContexts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeContext, setActiveContext] = useState(null);
  const [savingContext, setSavingContext] = useState(false);
  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  // Track mixpanel
  const { track } = useMixpanel();
  useEffect(() => {
    track("Contexts");
  }, []);

  // Get contexts
  useEffect(() => {
    setLoading(true);
    apiRequest.get("/contexts/").then((res) => {
      setContexts(get(res, "data", []));
      setLoading(false);
    });
  }, []);

  // Save context
  const saveContext = () => {
    setSavingContext(true);
    apiRequest.post(`/contexts/`, activeContext).then((res) => {
      setSavingContext(false);

      if (activeContext.id) {
        setContexts(
          contexts.map((c) => (c.id === activeContext.id ? activeContext : c))
        );
      } else {
        setContexts([...contexts, res.data]);
      }

      successNotification("Saved");
      setActiveContext(null);
    });
  };

  // Delete context
  const deleteContext = () => {
    setSavingContext(true);
    apiRequest
      .delete(`/contexts/`, { data: { id: activeContext.id } })
      .then((res) => {
        const response = get(res, "data");

        const error = get(response, "error");

        if (error) {
          errorNotification(error);
        } else {
          setActiveContext(null);
          successNotification("Deleted");
          setContexts(contexts.filter((c) => c.id !== activeContext.id));
        }
        setSavingContext(false);
      });
  };

  let fields = [
    {
      id: "label",
      placeholder: "Enter label",
      hint: "A short label for the context.",
    },
    {
      id: "content",
      placeholder: "Enter content",
      hint: "The content of the context.",
      componentId: "TextArea",
      height: "200px",
    },
  ];

  const activeContextId = get(activeContext, "id");

  return (
    <>
      {activeContext && (
        <Modal
          minWidth="800px"
          header={{
            title: activeContextId
              ? `Edit Context: ${activeContext.label}`
              : "Add Context",
          }}
          hide={() => setActiveContext(null)}
        >
          <Form
            fields={fields}
            onChange={(k, v) => setActiveContext({ ...activeContext, [k]: v })}
            submit={saveContext}
            submitText="Save Changes"
            value={activeContext}
            isFetching={savingContext}
            buttons={[
              {
                text: "Delete",
                onClick: () =>
                  setConfirmationModalData({
                    title: "Delete Context",
                    text: "Are you sure you want to delete this context? This is irreversible.",
                    confirmText: "Delete",
                    cancelText: "Cancel",
                    confirm: deleteContext,
                  }),
                type: "basic",
              },
            ]}
          />
        </Modal>
      )}
      <AdminWrapper
        title="Context"
        description="Context snippets can be included with your AI requests to increase the relevance and quality."
        buttons={[
          {
            text: "Add Context",
            size: "large",
            icon: "FiPlus",
            onClick: () => setActiveContext({}),
          },
        ]}
      >
        {loading ? (
          <Spinner />
        ) : (
          <Container>
            <Table
              data={{
                rows: contexts.map((c) => ({
                  ...c,
                  label: c.label || "No label",
                  content: truncate(c.content, { length: 100 }),
                })),
                columns: [
                  { id: "label", fontWeight: 400, flex: 1 },
                  { id: "content", flex: 2 },
                ],
                onRowClick: (i) => setActiveContext(get(contexts, i, null)),
              }}
            />
          </Container>
        )}
      </AdminWrapper>
    </>
  );
};

export default Context;

const Container = styled.div`
  background: white;
  border-radius: 10px;
  width: 100%;
  margin-top: 10px;
`;
