import { get } from "lodash";
import { getComponentIdOptions } from "./utils";

export const basicFields = ({
  account,
  fieldConfig,
  fieldType,
  isObjectArray = false,
  showAdvanced = false,
}) => {
  const contexts = get(account, "contexts", []);

  return [
    {
      id: "ai_generated",
      label: "AI Generated",
      orientation: "horizontal",
      width: "150px",
      componentId: "Switch",
      section: "AI Settings",
      value: get(fieldConfig, "ai_generated", false),
    },
    {
      id: "ai_context",
      label: "AI Context",
      componentId: "Select",
      section: "AI Settings",
      orientation: "horizontal",
      width: "200px",
      hint: "Select the relevant context items to pass in to the AI system to enhance its accuracy",
      multi: true,
      multiType: "array",
      value: get(fieldConfig, "ai_context", []),
      options: contexts.map((c) => ({ label: c.label, value: c.id })),
      displayCondition: () => get(fieldConfig, "ai_generated", false),
    },
    // {
    //   id: "ai_model",
    //   label: "AI Model",
    //   componentId: "Select",
    //   section: "AI Settings",
    //   orientation: "horizontal",
    //   width: "200px",
    //   value: get(fieldConfig, "ai_model", "gpt-4o-mini"),
    //   options: [
    //     { label: "gpt-4o", value: "gpt-4o" },
    //     { label: "gpt-4o-mini", value: "gpt-4o-mini" },
    //   ],
    //   displayCondition: () => get(fieldConfig, "ai_generated", false),
    // },
    {
      id: "ai_prompt",
      label: "AI Prompt",
      componentId: "TextArea",
      hint: "The prompt given to the AI to generate the field content.",
      section: "AI Settings",
      value: get(fieldConfig, "ai_prompt", ""),
      displayCondition: () => get(fieldConfig, "ai_generated", false),
    },
    {
      id: "componentId",
      label: "Field Type",
      componentId: "Select",
      section: "Field Settings",
      orientation: "horizontal",
      width: "200px",
      value: get(fieldConfig, "componentId", "Input"),
      defaultValue: "Input",
      hideEmptyItem: true,
      options: getComponentIdOptions(fieldType, isObjectArray),
      displayCondition: () =>
        isObjectArray ||
        fieldType.includes("string") ||
        fieldType === "integer",
    },
    {
      id: "label",
      label: "Label",
      componentId: "Input",
      orientation: "horizontal",
      width: "300px",
      section: "Field Settings",
      value: get(fieldConfig, "label"),
      hint: "Adjust the visual label",
      displayCondition: () => !get(fieldConfig, "hideCard"),
    },
    {
      id: "options",
      label: "Options",
      componentId: "KeyValuePairs",
      section: "Field Settings",
      hint: "Options for the select dropdown and the Enum option",
      useLabelAsKey: true,
      value: get(fieldConfig, "options", []),
      displayCondition: () => get(fieldConfig, "componentId") === "Select",
    },
    {
      id: "disabled",
      label: "Disabled",
      componentId: "Switch",
      section: "Other Settings",
      orientation: "horizontal",
      width: "150px",
      value: get(fieldConfig, "disabled", false),
      displayCondition: () =>
        showAdvanced &&
        ["Input", "TextArea", "MarkdownEditor", "Select"].includes(
          get(fieldConfig, "componentId")
        ),
    },
    {
      id: "description",
      label: "Description",
      componentId: "TextArea",
      section: "Other Settings",
      value: get(fieldConfig, "description"),
      displayCondition: () => showAdvanced && !get(fieldConfig, "hideCard"),
    },
    {
      id: "placeholder",
      label: "Placeholder",
      componentId: "Input",
      section: "Other Settings",
      value: get(fieldConfig, "placeholder"),
      hint: "The default text to appear in the input when blank",
      displayCondition: () =>
        showAdvanced &&
        ["Input", "TextArea"].includes(
          get(fieldConfig, "componentId", "Input")
        ),
    },
    {
      id: "hint",
      label: "Hint",
      componentId: "Input",
      section: "Other Settings",
      value: get(fieldConfig, "hint"),
      hint: "Additional information to help the user understand the field",
      displayCondition: () => showAdvanced && !get(fieldConfig, "hideCard"),
    },
    {
      id: "height",
      label: "Height",
      componentId: "Input",
      section: "Sizing",
      orientation: "horizontal",
      width: "150px",
      value: get(fieldConfig, "height"),
      hint: "The height of the component in pixels",
      displayCondition: () =>
        showAdvanced &&
        ["Image", "TextArea", "MarkdownEditor"].includes(
          get(fieldConfig, "componentId")
        ),
    },
    {
      id: "width",
      label: "Width",
      componentId: "Input",
      section: "Sizing",
      orientation: "horizontal",
      width: "150px",
      value: get(fieldConfig, "width"),
      hint: "The width of the component in pixels",
      displayCondition: () =>
        ["Image"].includes(get(fieldConfig, "componentId")),
    },
    {
      id: "objectFit",
      label: "Image Fit",
      componentId: "Select",
      section: "Image Settings",
      orientation: "horizontal",
      width: "150px",
      options: [
        { label: "Cover", value: "cover" },
        { label: "Contain", value: "contain" },
        { label: "Fill", value: "fill" },
      ],
      value: get(fieldConfig, "objectFit", "cover"),
      displayCondition: () =>
        showAdvanced && ["Image"].includes(get(fieldConfig, "componentId")),
    },
    {
      id: "characterLimit",
      label: "Character Limit",
      componentId: "Input",
      section: "Other Settings",
      orientation: "horizontal",
      type: "number",
      width: "150px",
      value: get(fieldConfig, "characterLimit"),
      hint: "The maximum number of characters allowed",
      displayCondition: () =>
        showAdvanced && get(fieldConfig, "componentId") === "ViewOnly",
    },
    {
      id: "link_text",
      label: "Link Text",
      componentId: "Input",
      section: "Other Settings",
      value: get(fieldConfig, "link_text"),
      hint: "The text to display for the link",
      displayCondition: () => get(fieldConfig, "componentId") === "Link",
    },
  ].filter(
    (f) =>
      !f.displayCondition ||
      (f.displayCondition && f.displayCondition(fieldConfig))
  );
};
