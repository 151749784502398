import { FormInputWrapper, Text } from "components";
import { get, startCase } from "lodash";

import FormField from "components/FormField";
import { rFormFieldsState } from "utils/recoil";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { useState } from "react";

const BasicForm = ({ isAdmin, value, fields, onChange, maxColumnSpan = 2 }) => {
  const [formFieldsState, setFormFieldsState] =
    useRecoilState(rFormFieldsState);

  return (
    <Container $gridcolumns={maxColumnSpan}>
      {fields
        // .filter((field) => field.componentId !== "Hidden")
        .map((field, fieldIndex) => {
          const componentId = get(field, "componentId", "Input");

          let columnSpan = get(field, "columnSpan", 1);

          // Set large components to max column span
          if (
            ["TextArea", "Image", "MarkdownEditor", "Video"].includes(
              componentId
            )
          ) {
            columnSpan = maxColumnSpan;
          }

          if (componentId === "Hidden") {
            return <></>;
          }

          const sharedObj = {
            ...field,
            isAdmin,
            columnSpan,
            label: get(field, "label", startCase(field.id)),
            labelFontSize: 16,
            labelFontWeight: 600,
            handleFieldClick: (anchor) =>
              setFormFieldsState({
                ...formFieldsState,
                anchorElement: anchor,
                activeFieldIndex: fieldIndex,
              }),
            // error: get(errors, field.id),
            onChange: (value) => onChange(field.key, value),
          };

          // ViewOnly
          if (componentId === "ViewOnly") {
            return (
              <FormInputWrapper key={fieldIndex} {...sharedObj}>
                <ViewOnly field={field} value={get(value, field.key)} />
              </FormInputWrapper>
            );
          }

          // Link
          if (componentId === "Link") {
            return (
              <FormInputWrapper key={fieldIndex} {...sharedObj}>
                <Text
                  data={{
                    text: get(field, "link_text", get(value, field.key)),
                    onClick: () => window.open(get(value, field.key), "_blank"),
                    color: "var(--primary)",
                  }}
                />
              </FormInputWrapper>
            );
          }

          // All other components
          return (
            <FormField
              key={fieldIndex}
              data={{
                ...sharedObj,
                componentId,
                padding: "16px",
                inputHeight: "48px",
                value: get(value, field.key),
              }}
            />
          );
        })}
    </Container>
  );
};

export default BasicForm;

const Container = styled.div`
  display: grid;
  gap: 20px;
  width: 100%;
  grid-template-columns: repeat(${(p) => parseInt(p.$gridcolumns)}, 1fr);
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ViewOnly = ({ field, value }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <Text
        data={{
          text: value || "No Value",
          characterLimit: expanded ? null : get(field, "characterLimit"),
          whiteSpace: "pre-wrap",
          wordBreak: "break-word",
        }}
      />

      {value &&
        get(field, "characterLimit") &&
        value.length > get(field, "characterLimit") && (
          <Text
            data={{
              text: expanded ? "View Less" : "View More",
              onClick: handleToggle,
              color: "var(--primary)",
              cursor: "pointer",
              fontSize: 14,
              fontWeight: 600,
              margin: "5px 0 0 0",
            }}
          />
        )}
    </div>
  );
};
