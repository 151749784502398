import { Icon, Spinner } from "./";

import React from "react";
import colors from "utils/colors";
import { get } from "lodash";
import { getPixels } from "utils/utils";
import styled from "styled-components";

export const StyledButton = styled.button`
  display: flex;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  letter-spacing: 0px;
  flex-direction: ${(p) => (p.flippedIcon ? "row-reverse" : "row")};
  border: 1px solid var(--primary);
  border-radius: ${(p) => getPixels(p.$borderradius || 10)};
  gap: 6px;
  width: ${(p) => p.width || "fit-content"};
  margin: ${(p) => p.$margin};

  &:hover {
    filter: brightness(90%);
  }

  opacity: ${(p) => (p.disabled ? "0.5" : "1.0")};
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};

  // Small
  ${(p) =>
    p.size === "small" &&
    `padding: 4px 8px 4px ${
      p.$hasicon && p.$hastext ? "5px" : "8px"
    }; font-weight: 500; font-size: 12px;`};

  // Medium
  ${(p) =>
    p.size === "medium" &&
    `padding: 10px 12px 10px ${
      p.$hasicon && p.$hastext ? "10px" : "12px"
    }; font-weight: 500; font-size: 14px;`};

  // Large
  ${(p) =>
    p.size === "large" &&
    `padding: 12px 24px 12px ${
      p.$hasicon && p.$hastext ? "20px" : "24px"
    }; font-weight: 500; font-size: 16px;`};

  // XLarge
  ${(p) =>
    p.size === "extraLarge" &&
    `padding: 16px 28px 16px ${
      p.$hasicon && p.$hastext ? "24px" : "28px"
    }; font-weight: 500; font-size: 20px;`};

  ${(p) => p.padding && `padding: ${p.padding};`}
`;

const Primary = styled(StyledButton)`
  background: ${(p) => get(p, "backgroundColor", colors.primary)};
  color: ${(p) => get(p, "color", "#ffffff")};
  border: 1px solid ${(p) => get(p, "backgroundColor", colors.primary)};
  ${(p) => p.border && `border: ${p.border};`}
`;

const BasicButton = styled(StyledButton)`
  color: ${(p) => get(p, "color", "var(--text-color)")};
  background: ${(p) => get(p, "backgroundColor", "var(--input-background)")};
  border: 1px solid ${(p) => get(p, "borderColor", "var(--divider)")};
  ${(p) => p.border && `border: ${p.border};`}
`;

const HollowWhiteButton = styled(StyledButton)`
  color: ${(p) => get(p, "color", "#ffffff")};
  background: ${(p) => get(p, "backgroundColor", "transparent")};
  border: 1px solid ${(p) => get(p, "borderColor", "white")};
  ${(p) => p.border && `border: ${p.border};`}
`;

const Button = ({ data }) => {
  const {
    onClick,
    text,
    isFetching,
    icon,
    disabled,
    size = "medium",
    type = "primary",
    backgroundColor,
    flippedIcon,
    padding,
    border,
    color,
    borderRadius,
    width,
    height = "auto",
  } = data;

  const buttonMap = {
    basic: BasicButton,
    primary: Primary,
    hollowWhite: HollowWhiteButton,
  };

  const iconStyles = {
    basic: {
      color: "#5D5F62",
    },
    primary: {
      color: "#ffffff",
    },
    hollowWhite: {
      color: "#ffffff",
    },
  };

  const iconSize = {
    small: 12,
    medium: 14,
    large: 20,
  };

  const ActiveButton = get(buttonMap, type);

  return (
    <ActiveButton
      $margin={data.margin}
      onClick={onClick}
      disabled={disabled}
      size={size}
      $hastext={!!text}
      $hasicon={!!icon}
      backgroundColor={backgroundColor}
      flippedIcon={flippedIcon}
      $borderradius={borderRadius}
      padding={padding}
      border={border}
      width={width}
      color={color}
      style={{ height }}
    >
      {icon && !isFetching && (
        <Icon
          data={{
            disabled,
            icon,
            ...get(iconStyles, type),
            size: get(iconSize, size),
            hover: true,
          }}
        />
      )}
      {isFetching && (
        <Spinner color={get(iconStyles, [type, "color"])} size={20} />
      )}
      {!isFetching && text && text}
    </ActiveButton>
  );
};

export default Button;
