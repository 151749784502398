import * as Components from "components";

import FormInputWrapper from "components/FormInputWrapper";
import { get } from "lodash";

const FormField = ({ data }) => {
  const { componentId } = data;
  const FormInput = get(Components, data.componentId);

  if (data.formText) {
    return <Components.Text data={data} />;
  }

  if (FormInput) {
    return (
      <FormInputWrapper {...data}>
        {!data.skeleton && (
          <FormInput
            data={{
              ...data,
              border: data.error ? "1px solid red" : data.border,
            }}
          />
        )}
      </FormInputWrapper>
    );
  }
  // NOT DEFINED
  return <FormInputWrapper {...data}>{componentId}</FormInputWrapper>;
};

export default FormField;
