import AdminWrapper from "components/AdminWrapper";
import Resource from "./Resource";
import styled from "styled-components";

// const colorOptions = [
//   "#df516e",
//   "#f79e44",
//   "#f7c744",
//   "#74c98f",
//   "#429ef0",
//   "#6945e1",
// ];

const brightColorOptions = [
  "#e6254c",
  "#ed851e",
  "#e9b31e",
  "#32c460",
  "#2488e0",
  "#5128da",
];

const resources = [
  {
    label: "Zapier Integration",
    type: "video",
    link: "https://intercom.help/curatorapp/en/articles/10303313-using-curator-s-zapier-integration",
    description: "Learn how to use Curator with the Zapier automation platform",
  },
  {
    label: "Make Integration",
    type: "article",
    link: "https://intercom.help/curatorapp/en/articles/10303118-using-curator-s-make-integration",
    description: "Learn how to use Curator with the Make automation platform",
  },
  {
    label: "Introduction To Curator",
    type: "article",
    link: "https://intercom.help/curatorapp/en/articles/10207474-curator-basics",
    description:
      "Learn about Curator and why human approval steps are important",
  },
  {
    label: "Style Customization",
    type: "article",
    link: "https://intercom.help/curatorapp/en/articles/10226409-style-customization",
    description:
      "Learn about the style customization options available in Curator",
  },
  {
    label: "Field Layout Customization",
    type: "article",
    link: "https://intercom.help/curatorapp/en/articles/10313719-field-layout-customization",
    description:
      "Learn about the field layout customization options available in Curator",
  },
  {
    label: "Confirm & Deny Actions",
    type: "article",
    link: "https://intercom.help/curatorapp/en/articles/10226631-submit-actions",
    description:
      "Learn how to configure confirm and deny actions for your step",
  },
  // {
  //   label: "Step Setup Guide",
  //   type: "article",
  //   link: "https://intercom.help/curatorapp/en/articles/10207720-confirmation-step-setup-guide",
  //   description:
  //     "A step-by-step guide on creating your first human confirmation step",
  // },
  {
    label: "Use-Case: YouTube Comment Replies",
    type: "video",
    link: "https://www.youtube.com/watch?v=8EN13z18TfI",
    description:
      "A 3-min video tutorial on building a YouTube comment reply dashboard",
  },
  // {
  //   label: "Intro Tutorial Video",
  //   type: "video",
  //   link: "https://youtu.be/9_AidpOnoj4",
  //   description:
  //     "A 7-min introduction and tutorial of creating a confirmation step",
  // },
  {
    label: "Approvals Dashboard",
    type: "article",
    link: "https://intercom.help/curatorapp/en/articles/10212569-sessions-dashboard",
    description:
      "Associate sessions with a specific user to enable the 'Sessions' dashboard",
  },
  {
    label: "Advanced Settings",
    type: "article",
    link: "https://intercom.help/curatorapp/en/articles/10226321-advanced-settings",
    description:
      "Configure advanced session settings via the Create Session endpoint",
  },
];

const Resources = () => {
  return (
    <AdminWrapper
      title="Help Resources"
      buttons={[
        {
          text: "View Help Center",
          onClick: () => {
            window.open("https://intercom.help/curatorapp", "_blank");
          },
          size: "large",
          icon: "FiExternalLink",
          type: "primary",
          isFetching: false,
        },
      ]}
    >
      <ResourcesContainer>
        {resources.map((r, i) => (
          <Resource
            key={i}
            {...r}
            color={brightColorOptions[i % brightColorOptions.length]}
            index={i}
          />
        ))}
      </ResourcesContainer>
    </AdminWrapper>
  );
};

export default Resources;

const ResourcesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;
`;
