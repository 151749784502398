import Cookies from "js-cookie";
import axios from "axios";
import { get } from "lodash";
import mixpanel from "mixpanel-browser";

export const baseApi = process.env.REACT_APP_API_URL;
export const webUrl = process.env.REACT_APP_WEB_URL;

export const getApiRequestHeaders = () => {
  let headersObj = {
    Authorization: `Token ${Cookies.get("accessToken")}`,
    "Content-Type": "application/json",
  };

  return headersObj;
};

export const handleError = (e) => {
  const response = get(e, "response", {});
  const status = get(response, "status");
  const message = get(response, ["data", "error"]);
  return { status, message };
};

export const apiRequest = axios.create({
  baseURL: baseApi,
  timeout: 28000,
});

const responseSuccessHandler = (response) => {
  return response;
};

const responseErrorHandler = (error) => {
  if (error.response?.status === 401) {
    // Add your logic to
    //  1. Redirect user to LOGIN
    //  2. Reset authentication from localstorage/sessionstorage

    Cookies.remove("accessToken");
    mixpanel.reset();
    window.location.href = "/";
  } else if (error.response?.status === 400) {
    // Handle 400 errors gracefully
    // You can add custom logic here, such as displaying a notification or logging the error
    return get(error, "response");
  }
  return Promise.reject(error);
};

apiRequest.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);

apiRequest.interceptors.request.use(
  (config) => {
    // Get the headers, but omit Content-Type if sending formData
    const headers = getApiRequestHeaders();

    if (config.data instanceof FormData) {
      delete headers["Content-Type"]; // Let Axios set this automatically for formData
    }

    config.headers = headers;

    config.params = {
      workspace: Cookies.get("curatorWorkspaceId"),
    };

    // Optionally pass an abort signal through the config if it exists
    if (config.abortSignal) {
      config.signal = config.abortSignal;
      delete config.abortSignal; // Remove it to clean up the config object
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
