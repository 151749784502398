import { convertJsonToSchema, sortFields } from "utils/utils";
import { rAccount, rFlow, rSession, rUser } from "utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import AdminWrapper from "components/AdminWrapper";
import Cookies from "js-cookie";
import LiveWrapper from "./LiveWrapper";
import { Loader } from "components";
import { SessionContent } from "./SessionContent";
import { apiRequest } from "utils/apiRequests";
import { errorNotification } from "utils/notification";
import { get } from "lodash";
import useMixpanel from "utils/useMixpanel";
import { useParams } from "react-router-dom";

const Session = ({ adminMode = false }) => {
  const { id } = useParams();
  const [originalSession, setOriginalSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sessionError, setSessionError] = useState(null);

  const [session, setSession] = useRecoilState(rSession);
  const setStep = useSetRecoilState(rFlow);
  const [account, setAccount] = useRecoilState(rAccount);
  const setUser = useSetRecoilState(rUser);

  const stepConfig = get(session, "step_config", null);

  // Track mixpanel
  const { track } = useMixpanel();
  useEffect(() => {
    track("Live Session", {
      session_id: id,
    });
  }, []);

  const accessToken = Cookies.get("accessToken");

  useEffect(() => {
    setLoading(true);
    const endpoint = accessToken ? "session" : "session_anonymous";
    apiRequest.get(`/${endpoint}/?id=${id}`).then((res) => {
      const responseData = get(res, "data", null);
      const error = get(responseData, "error", null);

      const resSession = get(responseData, "session", null);
      const resStep = get(responseData, "step", null);
      const resAccount = get(responseData, "account", null);
      const resUser = get(responseData, "user", null);

      const modifiedSession = {
        ...resSession,
        id,
      };

      if (error) {
        errorNotification(error);
        setLoading(false);
        setSessionError(error);
        return;
      } else {
        // Set session and backup
        setSession(modifiedSession);
        setOriginalSession(modifiedSession);

        // Set step
        setStep(resStep);

        if (!account) {
          // Set account
          setAccount(resAccount);
        }

        setUser(resUser);

        setLoading(false);
      }
    });
  }, [id]);

  // Loading state
  if (loading) {
    return <Loader />;
  }

  // This should not change during the session
  const originalSessionData = get(originalSession, "data", null);

  const rootSorting = get(stepConfig, ["_root", "sorting"], []);

  const schema = get(convertJsonToSchema(originalSessionData), "keys");

  const sortedSchema = sortFields({
    schema,
    sortingArray: rootSorting,
    objectKey: "key",
  });

  const Wrapper = adminMode ? AdminWrapper : LiveWrapper;

  // Render data editor
  return (
    <Wrapper session={{ account }}>
      <SessionContent
        sessionError={sessionError}
        schema={sortedSchema}
        adminMode={adminMode}
      />
    </Wrapper>
  );
};

export default Session;
