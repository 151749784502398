import { useLocation, useNavigate } from "react-router-dom";

import AccountMenu from "./AccountMenu";
import Button from "../Button";
import Dropdown from "../Dropdown";
import Icon from "../Icon";
import PlanUsageSection from "./PlanUsageSection";
import Row from "../Row";
import Text from "../Text";
import TrialSection from "./TrialSection";
import colors from "utils/colors";
import { get } from "lodash";
import logo from "images/curator-green.png";
import styled from "styled-components";

const NavigationItem = ({ item, isActive, onClick }) => (
  <StyledNavigationItem $active={isActive} onClick={onClick}>
    <Icon
      data={{
        icon: item.icon,
        size: 21,
        color: isActive ? "#242424" : "#474747",
        hover: true,
      }}
    />
    <Text
      data={{
        text: item.label,
        fontSize: 19,
        fontWeight: 500,
        color: isActive ? "#000000" : "#1a1a1a",
        cursor: "pointer",
      }}
    />
  </StyledNavigationItem>
);

const AdminWrapper = ({
  children,
  backFunction,
  backRoute,
  backText,
  title,
  description,
  buttons = [],
  menu = [],
  width,
}) => {
  const navigate = useNavigate();

  const location = useLocation();

  const items = [
    {
      label: "Flows",
      route: "flows",
      icon: "FiGrid",
      onClick: () => navigate("/admin/flows/"),
    },
    {
      label: "Approvals",
      route: "approvals",
      icon: "FiCheckSquare",
      onClick: () => navigate("/admin/approvals/"),
    },
    {
      label: "Users",
      route: "users",
      icon: "FiUsers",
      onClick: () => navigate("/admin/users/"),
    },
    {
      label: "Context",
      route: "context",
      icon: "FiFileText",
      onClick: () => navigate("/admin/context/"),
    },
  ];

  const bottomItems = [
    {
      label: "Help",
      route: "resources",
      icon: "FiLifeBuoy",
      onClick: () =>
        window.open(
          "https://intercom.help/curatorapp/en/collections/11023634-general",
          "_blank"
        ),
    },
    {
      label: "Billing",
      icon: "FiCreditCard",
      route: "billing",
      onClick: () => navigate("/admin/billing/"),
    },
    {
      label: "Settings",
      icon: "FiSettings",
      route: "settings",
      onClick: () => navigate("/admin/settings/"),
    },
  ];

  return (
    <AppContainer width={width}>
      <Navigation>
        <NavigationSection>
          <AppIcon src={logo} />
        </NavigationSection>

        <AccountMenu />

        <Sections>
          <Text
            data={{
              text: "Workspace",
              margin: "10px 0 5px 15px",
              fontSize: 18,
              color: "rgba(0,0,0,0.6)",
            }}
          />
          <ItemsContainer>
            {items.map((item, i) => {
              const isActive = get(location, "pathname").includes(item.route);

              return (
                <NavigationItem
                  key={i}
                  item={item}
                  isActive={isActive}
                  onClick={item.onClick}
                />
              );
            })}
          </ItemsContainer>

          <Text
            data={{
              text: "Resources",
              margin: "20px 0 5px 15px",
              fontSize: 18,
              color: "rgba(0,0,0,0.6)",
            }}
          />
          <ItemsContainer>
            {bottomItems.map((item, i) => {
              const isActive = get(location, "pathname").includes(item.route);

              return (
                <NavigationItem
                  key={i}
                  item={item}
                  isActive={isActive}
                  onClick={item.onClick}
                />
              );
            })}
          </ItemsContainer>
        </Sections>
        <TrialSection />
        <PlanUsageSection />
      </Navigation>
      <Content>
        {(backFunction ||
          backRoute ||
          title ||
          description ||
          menu.length > 0 ||
          buttons.length > 0) && (
          <Row
            $alignitems="center"
            $justifycontent="space-between"
            $margin="0 0 30px 0"
          >
            {(backFunction || backRoute) && (
              <Row
                $alignitems="center"
                $gap="10px"
                onClick={backRoute ? () => navigate(backRoute) : backFunction}
                $margin="0 10px 0 0"
              >
                <Icon
                  data={{
                    icon: "FiArrowLeft",
                    color: colors.darkGrey,
                    size: 30,
                    margin: "0 0 0 -4px",
                    hover: true,
                  }}
                />
                {backText && (
                  <Text
                    data={{
                      text: backText,
                      size: 22,
                      cursor: "pointer",
                      color: "rgba(0,0,0,0.5)",
                    }}
                  />
                )}
              </Row>
            )}

            {(title || description) && (
              <div>
                {title && (
                  <Text
                    data={{
                      text: title,
                      fontWeight: 700,
                      fontSize: 30,
                    }}
                  />
                )}
                {description && (
                  <Text
                    data={{
                      text: description,
                      fontWeight: 300,
                      fontSize: 18,
                      color: "rgba(0,0,0,0.5)",
                      margin: "4px 0 0 0",
                    }}
                  />
                )}
              </div>
            )}
            <Row
              $alignitems="center"
              $justifycontent="space-between"
              $gap="15px"
            >
              {menu.length > 0 && (
                <Dropdown
                  data={{
                    icon: {
                      icon: "FiMoreHorizontal",
                      size: 25,
                      color: colors.grey,
                      margin: "0 15px 0 0",
                      hover: true,
                    },
                    options: menu,
                  }}
                />
              )}
              {buttons.map((b, i) => (
                <Button key={i} data={b} style={{ fontSize: "30px" }} />
              ))}
            </Row>
          </Row>
        )}
        <Container>{children}</Container>
      </Content>
    </AppContainer>
  );
};

export default AdminWrapper;

const AppContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-right: 1px solid ${colors.inputBorder};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  min-width: 220px;
  max-width: 220px;
  background: white;
`;

const Content = styled.div`
  position: fixed;
  top: 0;
  left: 220px;
  right: 0px;
  bottom: 0;
  padding: 50px;
  overflow-y: auto;
`;

const NavigationSection = styled.div`
  border-bottom: 1px solid ${colors.inputBorder};
  padding: 15px 15px 10px 15px;
`;

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ItemsContainer = styled.div`
  padding: 5px 10px 0px 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledNavigationItem = styled.div`
  border-radius: 7px;
  padding: 7px 5px 7px 5px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  width: 100%;
  ${(p) => p.$active && `background: rgba(0, 0, 0, 0.05);`}
  &:hover {
    background: rgba(0, 0, 0, 0.02);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  width: 100%;
`;

const AppIcon = styled.img`
  height: 24px;
`;
