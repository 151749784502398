import { atom, selector } from "recoil";

export const rWorkspaces = atom({
  key: "workspaces",
  default: [],
});

export const rSlackChannels = atom({
  key: "slackChannels",
  default: [],
});

// The live session
export const rSession = atom({
  key: "session",
  default: null,
});

// The current or live step
export const rFlow = atom({
  key: "step",
  default: null,
});

export const rFlowChangeObject = atom({
  key: "stepChangeObject",
  default: {},
});

// A selector to update the step and the change object
export const rUpdateFlow = selector({
  key: "updateFlow",
  get: ({ get }) => {
    // This selector doesn't need to return a value for get
    return;
  },
  set: ({ set }, newValues) => {
    set(rFlow, (prevStep) => ({ ...prevStep, ...newValues }));
    set(rFlowChangeObject, (prevChangeObj) => ({
      ...prevChangeObj,
      ...newValues,
    }));
  },
});

export const rFormFieldsState = atom({
  key: "formFieldsState",
  default: {},
});

export const rEditorState = atom({
  key: "editorState",
  default: {
    activePath: null,
    anchorElement: null,
    showAdvanced: false,
  },
});

export const rHoverPath = atom({
  key: "hoverPath",
  default: null,
});

export const rIsFetchingAccount = atom({
  key: "isFetchingAccount",
  default: false,
});

export const rAccount = atom({
  key: "account",
  default: null,
});

export const rConfirmationModalData = atom({
  key: "confirmationModalData",
  default: null,
});

export const rUser = atom({
  key: "user",
  default: null,
});

export const rIsMixpanelInitialized = atom({
  key: "isMixpanelInitialized",
  default: false,
});

export const rIsMixpanelIdentified = atom({
  key: "isMixpanelIdentified",
  default: false,
});

export const rIntercomInitialized = atom({
  key: "intercomInitialized",
  default: false,
});
