import { Breadcrumb, Form, Menu, Text } from "components";
import { get, startCase } from "lodash";
import { getType, safeArray } from "utils/utils";
import { rAccount, rEditorState } from "utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import { basicFields } from "utils/formFields";
import colors from "utils/colors";

const HierarchyMenu = ({ config, onChange, originalData }) => {
  const [editorState, setEditorState] = useRecoilState(rEditorState);
  const { activePath, anchorElement, showAdvanced } = editorState;

  const account = useRecoilValue(rAccount);

  if (!activePath) return null;

  const activePathJoined =
    safeArray(activePath).length > 0
      ? activePath.filter((p) => p !== 0).join(".")
      : "_root";

  const activePathConfig = get(config, activePathJoined, {});

  const activePathData = get(originalData, activePath, {});

  const activePathType =
    get(activePathConfig, "type") || getType(activePathData);

  const isObjectArray =
    Array.isArray(activePathData) && typeof activePathData[0] === "object";

  const parentPath = activePath ? activePath.slice(0, -1) : [];
  const parentPathJoined = parentPath.filter((p) => p !== 0).join(".");
  const finalParentPath = parentPathJoined === "" ? null : parentPathJoined;
  const parentConfig = finalParentPath ? get(config, finalParentPath, {}) : {};

  const rootConfig = get(config, "_root", {});

  const parentType = get(parentConfig, "type");

  const parentIsGrid = get(parentConfig, "layout", "list") === "grid";

  const isRoot = get(activePath, 0) === "_root" || activePath.length === 0;

  const onFieldChange = (k, v) => {
    onChange({
      ...config,
      [activePathJoined]: {
        ...activePathConfig,
        [k]: v,
      },
    });
  };

  const getShowColumnSpan = () => {
    // Don't show columnSpan for object or array because they are forced to max width
    if (activePathType.includes("object") || activePathType.includes("array")) {
      return false;
    }

    // Show columnSpan for root if grid layout
    if (isRoot && get(rootConfig, "layout") === "grid") {
      return true;
    }

    // Show columnSpan if parent is object or object array and is grid layout
    if (
      (parentType === "object" || parentType === "object_array") &&
      parentIsGrid
    ) {
      return true;
    }

    // Otherwise, don't show columnSpan
    return false;
  };

  const layoutFields = [
    {
      id: "layout",
      label: "Field Layout",
      componentId: "Select",
      hint: "Whether to show the fields in a grid or vertical list layout",
      value: get(activePathConfig, "layout", "list"),
      options: [
        {
          label: "Vertical List",
          value: "list",
        },
        {
          label: "Grid",
          value: "grid",
        },
      ],
      displayCondition: () =>
        isObjectArray || activePathType.includes("object"),
    },
    {
      id: "gridItemWidth",
      label: "Grid Item Width",
      componentId: "Input",
      value: get(activePathConfig, "gridItemWidth", 250),
      hint: "The minimum width of the item in the grid layout in pixels, before stretching to fill the available space",
      displayCondition: () =>
        (isObjectArray || activePathType.includes("object")) &&
        get(activePathConfig, "layout") === "grid",
    },
  ];

  const basic = basicFields({
    account,
    fieldConfig: activePathConfig,
    fieldType: activePathType,
    isObjectArray,
    showAdvanced,
  });

  let fields = [
    ...basic,
    ...layoutFields,
    {
      id: "hideCard",
      label: "Remove Nesting",
      componentId: "Switch",
      value: get(activePathConfig, "hideCard", false),
      displayCondition: () => activePathType.includes("object"),
    },
    {
      id: "columnSpan",
      label: "Column Span",
      componentId: "Select",
      value: get(activePathConfig, "columnSpan", 1),
      hint: "The number of columns to span in the grid",
      options: [
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
      ],
      displayCondition: getShowColumnSpan,
    },
  ].filter(
    (f) =>
      !f.displayCondition ||
      (f.displayCondition && f.displayCondition(activePathConfig))
  );

  // Root has special layout fields
  if (isRoot) {
    fields = [...layoutFields];
  }

  return (
    <Menu
      background={"white"}
      anchorElement={anchorElement}
      hide={() => {
        setEditorState({
          activePath: null,
          anchorElement: null,
          showAdvanced: false,
        });
      }}
      width={"300px"}
    >
      <Breadcrumb
        fontSize={15}
        fontWeight={600}
        margin="0 0 20px 0"
        items={
          isRoot
            ? [{ text: "Root" }]
            : activePath
                .filter((p) => p !== 0)
                .map((p) => ({
                  text: startCase(p),
                }))
        }
      />
      <Form onChange={onFieldChange} fields={fields} />
      {!isRoot && (
        <Text
          data={{
            text: showAdvanced
              ? "Hide advanced settings"
              : "View more settings",
            fontWeight: 600,
            fontSize: 15,
            cursor: "pointer",
            color: colors.primary,
            margin: "15px 0 0 0",
            onClick: () => {
              setEditorState({
                ...editorState,
                showAdvanced: !showAdvanced,
              });
            },
          }}
        />
      )}
    </Menu>
  );
};

export default HierarchyMenu;
