import { Form, Text } from "components";
import { get, startCase } from "lodash";
import { rAccount, rFlow, rSlackChannels, rUpdateFlow } from "utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { apiRequest } from "utils/apiRequests";
import colors from "utils/colors";
import { useNavigate } from "react-router-dom";

const SlackSection = () => {
  const [isFetchingSlackChannels, setIsFetchingSlackLocations] =
    useState(false);

  const [showCustomizeEmailMessage, setShowCustomizeEmailMessage] =
    useState(false);
  const [slackChannels, setSlackChannels] = useRecoilState(rSlackChannels);
  const navigate = useNavigate();
  const updateFlow = useSetRecoilState(rUpdateFlow);

  const account = useRecoilValue(rAccount);
  const step = useRecoilValue(rFlow);

  const slackIntegrated = get(account, "slack_integrated", false);

  const slackLocation = get(step, "slack_location");
  const locationType = get(slackLocation, "type");

  const channelId = get(slackLocation, "id");

  const fetchSlackChannels = (type) => {
    const tempType = type || locationType;
    setIsFetchingSlackLocations(true);
    apiRequest.get(`slack/${tempType}s/`).then((res) => {
      const items = get(res, ["data", `${tempType}s`], []);
      setSlackChannels(items);
      setIsFetchingSlackLocations(false);
    });
  };

  const locationButtonText = `Load All ${startCase(locationType)}s`;

  const notificationMethod = get(step, "notification_method");

  // 1. Set the slack channels if a location is already selected
  useEffect(() => {
    if (slackLocation && slackChannels.length === 0) {
      setSlackChannels([slackLocation]);
    }
  }, [slackLocation]);

  // 2. Fetch channels if the type changes
  useEffect(() => {
    if (
      slackIntegrated &&
      locationType &&
      !channelId &&
      slackChannels.length === 0
    ) {
      fetchSlackChannels();
    }
  }, [locationType]);

  return (
    <div>
      <Form
        margin="0px 0 15px 0"
        fields={[
          {
            id: "notification_method",
            label: "Service",
            componentId: "Select",
            orientation: "horizontal",
            width: "200px",
            options: [
              { label: "None", value: null },
              { label: "Slack", value: "slack" },
              { label: "Email", value: "email" },
            ],
            value: get(step, "notification_method"),
            onChange: (k, v) => {
              setSlackChannels([]);
              updateFlow({
                notification_method: v,
                slack_location: null,
              });
            },
          },
          {
            id: "notification_recipients",
            label: "Recipients",
            componentId: "TextArea",
            value: get(step, "notification_recipients"),
            hint: "The email addresses to send the notification to. Separate multiple addresses with a comma.",
            onChange: (k, v) => updateFlow({ [k]: v }),
            displayCondition: () => notificationMethod === "email",
          },
          {
            id: "notification_subject",
            label: "Notification Subject",
            componentId: "Input",
            value: get(step, "notification_subject"),
            hint: "Customize the subject for the notification email.",
            onChange: (k, v) => updateFlow({ [k]: v }),
            displayCondition: () =>
              notificationMethod === "email" && showCustomizeEmailMessage,
          },
          {
            id: "notification_message",
            label: "Custom Notification Message",
            componentId: "TextArea",
            value: get(step, "notification_message"),
            hint: "Customize the default notification message sent to the user. The message will always include the session link.",
            onChange: (k, v) => updateFlow({ [k]: v }),
            displayCondition: () =>
              notificationMethod === "email" && showCustomizeEmailMessage,
          },
          {
            text: "Customize Subject & Message",
            componentId: "Text",
            formText: true,
            color: colors.primary,
            fontWeight: 600,
            fontSize: 14,
            padding: "0px",
            margin: "0px",
            onClick: () => setShowCustomizeEmailMessage(true),
            displayCondition: () =>
              notificationMethod === "email" && !showCustomizeEmailMessage,
          },
        ].filter(
          (f) =>
            !f.displayCondition || (f.displayCondition && f.displayCondition())
        )}
      />

      {!notificationMethod && (
        <Text
          data={{
            text: "Warning: No approval notifications configured.",
            fontSize: 14,
            fontWeight: 300,
          }}
        />
      )}

      {notificationMethod === "slack" && (
        <>
          {!slackIntegrated && (
            <>
              <Text
                data={{
                  text: "Connect your Slack account to enable this feature",
                  fontSize: 14,
                  fontWeight: 300,
                  margin: "0 0 15px 0",
                }}
              />
              <Text
                data={{
                  text: "Go to Settings",
                  fontSize: 14,
                  fontWeight: 600,
                  color: colors.primary,
                  onClick: () => navigate("/admin/settings"),
                }}
              />
            </>
          )}
          {slackIntegrated && (
            <>
              <Form
                margin="10px 0 0 0"
                fields={[
                  {
                    id: "type",
                    label: "Slack Location Type",
                    componentId: "Select",
                    options: [
                      { label: "Channel", value: "channel" },
                      { label: "User", value: "user" },
                    ],
                    value: get(slackLocation, "type"),
                    onChange: (k, v) => {
                      setSlackChannels([]);
                      updateFlow({
                        slack_location: {
                          ...slackLocation,
                          type: v,
                          id: null,
                        },
                      });
                      fetchSlackChannels(v);
                    },
                  },
                  {
                    id: "slack_location",
                    label: "Slack Channel",
                    componentId: "Select",
                    options: slackChannels.map((c) => ({
                      label: c.name,
                      value: c.id,
                    })),
                    value: get(slackLocation, "id"),
                    onChange: (k, v) => {
                      const matchingChannel = slackChannels.find(
                        (c) => c.id === v
                      );
                      updateFlow({
                        slack_location: {
                          ...slackLocation,
                          ...matchingChannel,
                        },
                      });
                    },
                    displayCondition: () =>
                      !isFetchingSlackChannels && locationType,
                  },
                  {
                    id: "slack_location_button",
                    componentId: "Button",
                    label: null,
                    text: locationButtonText,
                    isFetching: isFetchingSlackChannels,
                    type: "basic",
                    icon: "FiRefreshCcw",
                    onClick: () => fetchSlackChannels(),
                    displayCondition: () =>
                      locationType && slackChannels.length <= 1,
                  },
                  {
                    id: "notification_message",
                    label: "Custom Notification Message",
                    componentId: "TextArea",
                    value: get(step, "notification_message"),
                    hint: "Customize the default message send to the user when they confirm their data.",
                    onChange: (k, v) => updateFlow({ [k]: v }),
                    displayCondition: () =>
                      !isFetchingSlackChannels && locationType && channelId,
                  },
                ].filter(
                  (f) =>
                    !f.displayCondition ||
                    (f.displayCondition && f.displayCondition())
                )}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SlackSection;
