import { Button, Row, Text } from "components";
import { useLocation, useNavigate } from "react-router-dom";

import ProgressBar from "components/ProgressBar";
import colors from "utils/colors";
import { get } from "lodash";
import { rAccount } from "utils/recoil";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

const PlanUsageSection = () => {
  const account = useRecoilValue(rAccount);

  const billing = get(account, ["billing"], null);

  const subscription = get(billing, ["subscription"], null);

  const flowsCount = get(billing, "flows_count", 0);
  const flowsLimit = get(subscription, "flows", 0);
  const flowsPercent = (flowsCount / flowsLimit) * 100;

  const creditLimit = get(billing, "credit_limit", 0);
  const creditUsage = get(billing, "credit_usage", 0);
  const creditsPercent = (creditUsage / creditLimit) * 100;

  const navigate = useNavigate();

  const location = useLocation();
  const pathname = get(location, "pathname", "");
  const isBilling = pathname.includes("/admin/billing/");

  return (
    <>
      {subscription && !isBilling && (
        <TrialDays>
          <Text
            data={{
              text: "Your Usage",
              fontSize: 14,
              fontWeight: 700,
              color: "#1a1a1a",
            }}
          />

          <Row $justifycontent="space-between" $alignitems="center">
            <Text
              data={{
                text: "Approval Flows",
                fontSize: 14,
                fontWeight: 500,
                color: "#1a1a1a",
                margin: "10px 0 5px 0",
              }}
            />
            <Text
              data={{
                text: `${flowsCount} / ${flowsLimit}`,
                fontSize: 14,
                fontWeight: 500,
                color: "#1a1a1a",
                margin: "10px 0 5px 0",
              }}
            />
          </Row>
          <ProgressBar percent={flowsPercent} />

          <Row $justifycontent="space-between" $alignitems="center">
            <Text
              data={{
                text: "Credits",
                fontSize: 14,
                fontWeight: 500,
                color: "#1a1a1a",
                margin: "10px 0 5px 0",
              }}
            />
            <Text
              data={{
                text: `${creditUsage} / ${creditLimit}`,
                fontSize: 14,
                fontWeight: 500,
                color: "#1a1a1a",
                margin: "10px 0 5px 0",
              }}
            />
          </Row>
          <ProgressBar percent={creditsPercent} />

          <Button
            data={{
              text: "Manage Plan",
              margin: "10px 0 0 0",
              width: "100%",
              type: "basic",
              onClick: () => navigate("/admin/billing/"),
              icon: "FiArrowRight",
              flippedIcon: true,
            }}
          />
        </TrialDays>
      )}
    </>
  );
};

export default PlanUsageSection;

const TrialDays = styled.div`
  padding: 10px 10px 10px 10px;
  border-top: 1px solid ${colors.inputBorder};
`;
