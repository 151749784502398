import { Button, EditableText, Icon, Row, Text } from "components";
import { get, isEmpty, isEqual, startCase } from "lodash";
import { getPixels, safeArray } from "utils/utils";
import {
  rEditorState,
  rFlow,
  rHoverPath,
  rSession,
  rUpdateFlow,
  rUser,
} from "utils/recoil";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import BasicForm from "./BasicForm";
import HierarchyEditor from "components/HierarchyEditor";
import { apiRequest } from "utils/apiRequests";
import colors from "utils/colors";
import { errorNotification } from "utils/notification";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export const SessionContent = ({
  isAdmin, // This is the admin PREVIEWING the session in the setup mode
  schema,
  sessionError,
  adminMode = false, // This is the admin actually using the dashboard
}) => {
  // Local State
  const [submitting, setSubmitting] = useState(false);
  const [activeDataPath, setActiveDataPath] = useState([]);
  const [redirectMessage, setRedirectMessage] = useState(null);

  // Recoil State
  const [session, setSession] = useRecoilState(rSession);
  const step = useRecoilValue(rFlow);
  const setEditorState = useSetRecoilState(rEditorState);
  const [hoverPath, setHoverPath] = useRecoilState(rHoverPath);
  const updateFlow = useSetRecoilState(rUpdateFlow);

  const user = useRecoilValue(rUser);

  const stepConfig = get(step, "config", null);

  const sessionData = get(session, "data", null);

  const pageWidth = get(step, "pageWidth", 800);

  const navigate = useNavigate();

  const stepFields = safeArray(step, "fields");

  const hasFields = stepFields.length > 0;

  const confirmAction = get(step, "confirm_action", null);
  const denyAction = get(step, "deny_action", null);

  const handleRedirect = (actionType) => {
    const matchingAction = get(step, actionType, null);

    const redirectType = get(matchingAction, "redirect_type", null);

    if (redirectType === "next_session") {
      if (nextSessionId) {
        navigate(`/session/${nextSessionId}`);
      } else {
        navigate("/approvals");
      }
    } else if (redirectType === "url") {
      const redirectUrl = get(matchingAction, "redirect_url", null);
      window.location.href = redirectUrl;
    } else if (redirectType === "dashboard") {
      navigate("/approvals");
    } else if (redirectType === "message") {
      setRedirectMessage(get(matchingAction, "redirect_message", null));
    }
  };

  const handleSubmit = (actionType) => {
    const matchingAction = get(step, actionType, null);
    const hasCallbackUrl = get(step, "has_callback_url", false);

    // Don't run action in admin
    if (isAdmin) {
      errorNotification("This won't work in the admin setup mode");
      return null;
    }

    // Handle Confirm Action
    if (!hasCallbackUrl && (!matchingAction || isEmpty(matchingAction))) {
      errorNotification(`Error - ${startCase(actionType)} is not configured`);
      return null;
    }

    setSubmitting(true);

    const endpoint = get(matchingAction, "endpoint", null);

    // 1. Send result via our API to their Endpoint
    apiRequest
      .post("/handle_result/", {
        id: get(session, "id", null),
        data: sessionData,
        action_type: actionType,
      })
      .then((res) => {
        setSubmitting(false);

        // Run redirect after response
        if (endpoint) {
          handleRedirect(actionType);
        }
      });

    // Run redirect instantly?
    if (!endpoint) {
      handleRedirect(actionType);
    }
  };

  const nextSessionId = get(session, "next_session", null);

  // Error message
  if (sessionError) {
    return (
      <RedirectContent $maxWidth={pageWidth}>
        <RedirectMessage>{sessionError}</RedirectMessage>
        {nextSessionId && (
          <Button
            data={{
              text: "Go To Approvals",
              onClick: () => navigate("/approvals"),
              size: "large",
              type: "basic",
              margin: "30px 0 0 0",
            }}
          />
        )}
      </RedirectContent>
    );
  }

  // Redirect message
  if (redirectMessage) {
    return (
      <RedirectContent $maxWidth={pageWidth}>
        <RedirectMessage>{redirectMessage}</RedirectMessage>
        {nextSessionId && (
          <Button
            data={{
              text: "Go To Approvals",
              onClick: () => navigate("/approvals"),
              size: "large",
              type: "basic",
              margin: "30px 0 0 0",
            }}
          />
        )}
      </RedirectContent>
    );
  }

  const rootPath = activeDataPath || ["_root"];
  const rootPathJoined = rootPath
    .filter((p) => typeof p !== "number")
    .join(".");

  const completedDate = get(session, "completed_date", null);

  // Render editor
  return (
    <Content $maxWidth={pageWidth}>
      {(nextSessionId || user) && (!isAdmin || adminMode) && (
        <Row
          $gap="5px"
          $alignitems="center"
          $margin="0 0 20px 0"
          onClick={() =>
            navigate(adminMode ? "/admin/approvals" : "/approvals")
          }
        >
          <Icon
            data={{
              icon: "FiArrowLeft",
              size: 20,
              margin: "1px 0 0 0",
              hover: true,
            }}
          />
          <Text
            data={{
              text: "All Approvals",
              cursor: "pointer",
              color: "#727272",
            }}
          />
        </Row>
      )}
      <HeaderRow>
        <div style={{ width: "100%" }}>
          <EditableText
            editable={isAdmin}
            fontSize={26}
            fontWeight={600}
            value={isAdmin ? get(step, "title") : get(session, "title")}
            placeholder="Enter a title here"
            onChange={(v) => updateFlow({ title: v })}
            minWidth="100%"
          />
        </div>

        {!completedDate && (
          <Row $gap="10px">
            {denyAction && (
              <Button
                data={{
                  text: get(denyAction, "label", "Deny") || "Deny",
                  size: "large",
                  type: "basic",
                  isFetching: submitting,
                  onClick: () => handleSubmit("deny_action"),
                }}
              />
            )}

            <Button
              data={{
                text: get(confirmAction, "label", "Confirm") || "Confirm",
                size: "large",
                backgroundColor: "var(--primary)",
                isFetching: submitting,
                onClick: () => handleSubmit("confirm_action"),
              }}
            />
          </Row>
        )}
      </HeaderRow>

      {hasFields && (
        <WhiteCard>
          <BasicForm
            maxColumnSpan={get(step, "maxColumnSpan", 2)}
            isAdmin={isAdmin}
            value={sessionData}
            fields={stepFields}
            onChange={(k, v) => {
              setSession({
                ...session,
                data: {
                  ...sessionData,
                  [k]: v,
                },
              });
            }}
          />
        </WhiteCard>
      )}

      {sessionData && !isEmpty(sessionData) && !hasFields && (
        <WhiteCard
          onMouseOver={() => setHoverPath(rootPathJoined)}
          onMouseLeave={() => setHoverPath(null)}
          $active={isAdmin && isEqual(rootPathJoined, hoverPath)}
          onClick={(e) => {
            if (isAdmin) {
              e.stopPropagation();
              setEditorState({
                activePath: rootPath,
                anchorElement: e.currentTarget,
                showAdvanced: false,
              });
            }
          }}
        >
          <HierarchyEditor
            isAdmin={isAdmin}
            config={stepConfig}
            schema={schema}
            data={sessionData}
            activeDataPath={activeDataPath}
            setActiveDataPath={setActiveDataPath}
            onChange={(v) => {
              setSession({ ...session, data: v });
            }}
          />
        </WhiteCard>
      )}
    </Content>
  );
};

const RedirectContent = styled.div`
  width: 100%;
  max-width: ${(p) => getPixels(p.$maxWidth || 800)};
  margin: 0 auto;
  padding: 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`;

const Content = styled.div`
  width: 100%;
  max-width: ${(p) => getPixels(p.$maxWidth || 800)};
  margin: 0 auto;
`;

const RedirectMessage = styled.div`
  font-size: 35px;
  font-weight: 600;
  text-align: center;
`;

const WhiteCard = styled.div`
  background: white;
  padding: 25px;
  border-radius: 10px;
  border: 1px solid ${colors.inputBorder};
  ${(p) => p.$active && `border: 1px solid blue;`}
  @media (max-width: 800px) {
    padding: 20px;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin: 0 0 40px 0;
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
    margin: 0 0 20px 0;
  }
`;
