import { Button, Hint, Icon, Modal, Row, Text } from "components";
import { errorNotification, successNotification } from "utils/notification";
import { getAccountTrialDays, getUrlParameter } from "utils/utils";
import { rAccount, rConfirmationModalData, rUser } from "utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import AdminWrapper from "components/AdminWrapper";
import { apiRequest } from "utils/apiRequests";
import colors from "utils/colors";
import { get } from "lodash";
import moment from "moment";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import useMixpanel from "utils/useMixpanel";

const Billing = () => {
  // Track mixpanel
  const { track } = useMixpanel();

  const [account, setAccount] = useRecoilState(rAccount);
  const user = useRecoilValue(rUser);

  useEffect(() => {
    if (user) {
      track("Billing");
    }
  }, []);

  const trialDaysRemaining = getAccountTrialDays(account);

  const billing = get(account, "billing", null);

  const subscription = get(billing, "subscription", null);

  const creditUsage = get(billing, "credit_usage", 0);
  const creditLimit = get(billing, "credit_limit", 50);
  const hasPayments = get(billing, "has_payments", false);
  const flowsLimit = get(subscription, "flows", 1);
  const flowsCount = get(billing, "flows_count", 0);

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const hasStripeCustomerId = get(billing, "has_stripe_customer_id", false);

  const testMode = true;
  const portalId = testMode ? "test_bIY7u8aiqeoZ2m44gg" : "14k7sK74Z6XO8M0288";
  const portalLink = `https://billing.stripe.com/p/login/${portalId}?prefilled_email=${get(
    user,
    "email",
    ""
  )}`;

  const location = useLocation();

  const action = getUrlParameter("action", location);
  const result = getUrlParameter("result", location);
  const sessionId = getUrlParameter("session_id", location);

  const [isFinalizingSession, setIsFinalizingSession] = useState(false);
  const [isCreatingSession, setIsCreatingSession] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);

  const [changeSubscriptionModal, setChangeSubscriptionModal] = useState(null);

  const modalFlowsCount = get(changeSubscriptionModal, "flows", 1);

  // Change the subscription based on the user's selection
  const changeSubscription = async () => {
    if (subscription) {
      // HAS SUBSCRIPTION - UPDATE SUBSCRIPTION
      setIsFinalizingSession(true);
      apiRequest
        .post("/stripe/update_subscription/", {
          flows: modalFlowsCount,
        })
        .then((response) => {
          const sub = get(response, ["data", "subscription"]);
          saveNewSubscription(sub);
          setIsFinalizingSession(false);
          setChangeSubscriptionModal(null);
        });
    } else if (hasStripeCustomerId) {
      // NO SUBSCRIPTION BUT HAS CARD ON FILE - CREATE SUBSCRIPTION DIRECTLY
      setIsFinalizingSession(true);
      apiRequest
        .post(`/stripe/create_subscription/`, {
          flows: modalFlowsCount,
        })
        .then((response) => {
          const sub = get(response, ["data", "subscription"]);
          saveNewSubscription(sub);
          setIsFinalizingSession(false);
          setChangeSubscriptionModal(null);
        });
    } else {
      // NO SUBSCRIPTION AND NO CARD ON FILE - CREATE CREATE SESSION
      setIsCreatingSession(true);
      apiRequest
        .post(`/stripe/session/`, {
          flows: modalFlowsCount,
        })
        .then((response) => {
          const sessionUrl = get(response, ["data", "session", "url"]);
          window.location.href = sessionUrl;
          setIsCreatingSession(false);
          setChangeSubscriptionModal(null);
        });
    }
  };

  // Handle the Stripe session result
  useEffect(() => {
    if (
      user &&
      action === "payment" &&
      result === "success" &&
      sessionId &&
      !isFinalizingSession
    ) {
      setIsFinalizingSession(true);
      apiRequest
        .post("/stripe/auth/", {
          session_id: sessionId,
        })
        .then((response) => {
          const cardSaved = get(response, ["data", "card_saved"]);
          if (cardSaved) {
            const sub = get(response, ["data", "subscription"]);
            saveNewSubscription(sub);
          }
          setIsFinalizingSession(false);
        });
    }
  }, []);

  // Set the new subscription to recoil state
  const saveNewSubscription = (sub) => {
    setAccount({
      ...account,
      billing: {
        ...billing,
        credit_limit: sub.credits * sub.flows,
        has_stripe_customer_id: true,
        subscription: sub,
      },
    });
    successNotification("Subscription Changed");
  };

  const getButtonObj = () => {
    if (subscription) {
      return {
        text: "Update Subscription",
        isFetching: isFinalizingSession,
        onClick: () =>
          setChangeSubscriptionModal({
            flows: flowsLimit,
          }),
      };
    }

    // No subscription
    return {
      text: "Upgrade Now",
      isFetching: isCreatingSession,
      onClick: () =>
        setChangeSubscriptionModal({
          flows: flowsLimit,
        }),
    };
  };

  const buttonObj = getButtonObj();

  const calculatedPrice = modalFlowsCount * 10 + 10;

  // Cancel active subscription
  const cancelSubscription = () => {
    setConfirmationModalData({
      title: "Cancel Subscription",
      text: "Are you sure you want to cancel your subscription? All your approval flows will become inactive at the end of your billing period.",
      confirmText: "Yes - Cancel Subscription",
      cancelText: "No - Keep Subscription",
      isFetching: isCancelling,
      confirm: () => {
        setIsCancelling(true);
        apiRequest.post("/stripe/cancel/").then(() => {
          setAccount({
            ...account,
            billing: {
              ...billing,
              credit_limit: 50,
              subscription: null,
            },
          });
          successNotification("Plan Cancelled");
          setIsCancelling(false);
        });
      },
    });
  };

  const isUnpaid = !hasPayments && !subscription;
  const isUnpaidAndTrialEnded = isUnpaid && trialDaysRemaining <= 0;

  return (
    <AdminWrapper
      title="Billing"
      description="Manage your active subscription plan"
    >
      {changeSubscriptionModal && (
        <Modal
          hide={() => setChangeSubscriptionModal(null)}
          header={{
            title: subscription
              ? "Modify Curator Subscription"
              : "Create Curator Subscription",
          }}
          buttons={[
            {
              text: "Confirm Subscription Change",
              onClick: changeSubscription,
              isFetching: isCreatingSession || isFinalizingSession,
              disabled: flowsLimit === modalFlowsCount,
            },
          ]}
        >
          <Text
            data={{
              text: "How many approval flows do you need?",
              fontSize: 20,
              fontWeight: 400,
              margin: "0 0 10px 0",
            }}
          />
          <Row $gap="10px" $alignitems="center">
            <Row $gap="10px" $alignitems="center">
              <Icon
                data={{
                  icon: "FiMinusCircle",
                  size: 24,
                  hover: true,
                  color:
                    modalFlowsCount > flowsCount
                      ? "rgba(0, 0, 0, 0.5)"
                      : "rgba(0, 0, 0, 0.3)",
                  onClick: () => {
                    // Don't allow the user to go below the current number of flows
                    if (modalFlowsCount > flowsCount) {
                      setChangeSubscriptionModal({
                        flows: modalFlowsCount - 1,
                      });
                    } else {
                      errorNotification(
                        "Cannot go below your current flow count"
                      );
                    }
                  },
                }}
              />
              <Text
                data={{
                  text: modalFlowsCount,
                  fontSize: 40,
                  fontWeight: 600,
                }}
              />
              <Icon
                data={{
                  icon: "FiPlusCircle",
                  size: 24,
                  hover: true,
                  color: "rgba(0, 0, 0, 0.5)",
                  onClick: () =>
                    setChangeSubscriptionModal({
                      flows: modalFlowsCount + 1,
                    }),
                }}
              />
            </Row>
            <Text
              data={{
                text: `${
                  flowsLimit === modalFlowsCount && !isUnpaid
                    ? "Current Price"
                    : "New Price"
                }: $${calculatedPrice} per month.`,
                fontSize: 24,
                fontWeight: 600,
                margin: "10px 0 10px 0",
                color: colors.primaryLight,
              }}
            />
          </Row>
          <Row $gap="5px" $alignitems="center" $margin="20px 0 0 0">
            <Text
              data={{
                text: `You're currently using ${flowsCount} approval flow${
                  flowsCount > 1 ? "s" : ""
                }.`,
                fontSize: 16,
                fontWeight: 400,
              }}
            />
            <Text
              data={{
                text: "What are approval flows?",
                fontSize: 16,
                fontWeight: 600,
                color: colors.primaryLight,
                onClick: () => window.open("https://intercom.help/curatorapp/"),
              }}
            />
          </Row>
        </Modal>
      )}
      <Container>
        {!isUnpaidAndTrialEnded && (
          <>
            <Text
              data={{
                text: `Your Usage:`,
                fontSize: 20,
                fontWeight: 600,
                color: "rgba(0, 0, 0, 0.7)",
                margin: "0 0 20px 0",
              }}
            />

            <Row $gap="10px" $alignitems="center">
              <Text
                data={{
                  text: `Approval Flows:`,
                  fontSize: 24,
                  fontWeight: 600,
                }}
              />

              <Text
                data={{
                  text: `${flowsCount} / ${flowsLimit}`,
                  fontSize: 24,
                  fontWeight: 400,
                }}
              />
              <Hint
                hint="Approval Flows are configurations of specific approval workflows."
                margin="5px 0 0 0"
              />
            </Row>

            <Row $gap="10px" $margin="10px 0 0 0" $alignitems="center">
              <Text
                data={{
                  text: `${hasPayments ? "" : "Monthly"} Credits:`,
                  fontSize: 24,
                  fontWeight: 600,
                }}
              />

              <Text
                data={{
                  text: `${creditUsage} / ${creditLimit}`,
                  fontSize: 24,
                  fontWeight: 400,
                }}
              />
              <Hint
                hint="Credits are used to run approval flows and AI generation features in the flows. Credit limits are reset monthly."
                margin="5px 0 0 0"
              />
            </Row>
          </>
        )}

        {isUnpaid && trialDaysRemaining > 0 && (
          <FreeTrialBanner>
            You have {trialDaysRemaining} days remaining in your free trial.
          </FreeTrialBanner>
        )}

        {isUnpaidAndTrialEnded && (
          <FreeTrialBanner>
            Your trial has ended. To continue using Curator, upgrade your plan
          </FreeTrialBanner>
        )}

        {!hasPayments && (
          <Button
            data={{
              margin: "30px 0 0 0",
              size: "large",
              isFetching: isFinalizingSession,
              ...buttonObj,
            }}
          />
        )}

        {subscription && !hasPayments && (
          <>
            <Text
              data={{
                text: "Need to cancel or change your payment method?",
                fontSize: 16,
                fontWeight: 400,
                margin: "30px 0 10px 0",
              }}
            />

            <Row $gap="10px" $alignitems="center">
              <Button
                data={{
                  size: "large",
                  text: "Cancel Subscription",
                  type: "basic",
                  onClick: cancelSubscription,
                  isFetching: isCancelling,
                }}
              />
              <Button
                data={{
                  size: "large",
                  text: "Manage Payment Method",
                  type: "basic",
                  onClick: () => (window.location.href = portalLink),
                }}
              />
            </Row>
          </>
        )}
      </Container>
    </AdminWrapper>
  );
};

export default Billing;

const FreeTrialBanner = styled.div`
  color: ${colors.primaryLight};
  border-radius: 8px;
  margin: 20px 0 20px 0;
  font-size: 20px;
  font-weight: 600;
`;

const Container = styled.div`
  background: white;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid ${colors.inputBorder};
`;
