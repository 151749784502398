import { Form, Modal, Spinner, Table } from "components";
import { errorNotification, successNotification } from "utils/notification";
import { get, startCase } from "lodash";
import { rConfirmationModalData, rUser } from "utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import AdminWrapper from "components/AdminWrapper";
import { apiRequest } from "utils/apiRequests";
import styled from "styled-components";
import useMixpanel from "utils/useMixpanel";

const Users = () => {
  const user = useRecoilValue(rUser);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeUser, setActiveUser] = useState(null);
  const [savingUser, setSavingUser] = useState(false);
  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  // Track mixpanel
  const { track } = useMixpanel();

  // Get users
  useEffect(() => {
    if (user) {
      track("Users");
      setLoading(true);
      apiRequest.get("/users/").then((res) => {
        setUsers(get(res, "data", []));
        setLoading(false);
      });
    }
  }, [user]);

  const validateForm = () => {
    if (activeUser.id) {
    } else {
      if (!activeUser.email) {
        errorNotification("Email is required");
        return false;
      }

      if (!activeUser.password) {
        errorNotification("Password is required");
        return false;
      }
    }

    return true;
  };

  // Save user
  const saveUser = () => {
    if (!validateForm()) {
      return;
    }

    setSavingUser(true);
    apiRequest.post(`/users/`, activeUser).then((res) => {
      setSavingUser(false);

      if (activeUser.id) {
        setUsers(users.map((u) => (u.id === activeUser.id ? activeUser : u)));
      } else {
        setUsers([...users, res.data]);
      }

      successNotification("Saved");
      setActiveUser(null);
    });
  };

  const isAccountOwner = get(activeUser, "admin", false);

  // Delete user
  const deleteUser = (action) => {
    setSavingUser(true);
    apiRequest
      .delete(`/users/`, { data: { id: activeUser.id, action } })
      .then((res) => {
        const response = get(res, "data");

        const error = get(response, "error");

        if (error) {
          errorNotification(error);
        } else {
          setActiveUser(null);
          successNotification("Deleted");
          setUsers(users.filter((u) => u.id !== activeUser.id));
        }
        setSavingUser(false);
      });
  };

  let fields = [
    {
      id: "first_name",
      section: "Basics",
      orientation: "horizontal",
      width: "250px",
      placeholder: "Jane",
    },
    {
      id: "last_name",
      section: "Basics",
      orientation: "horizontal",
      width: "250px",
      placeholder: "Smith",
    },
    {
      id: "email",
      placeholder: "email@example.com",
      section: "Basics",
      hint: "The email address of the user. This will be used to login.",
      displayCondition: () => !activeUser.id,
      orientation: "horizontal",
      width: "250px",
    },
    {
      id: "password",
      type: "password",
      placeholder: "Password",
      section: "Basics",
      hint: "The user will need this to login to sessions assigned specifically to them.",
      displayCondition: () => !activeUser.id,
      orientation: "horizontal",
      width: "250px",
    },
    {
      id: "role",
      hint: "Admins have access to your entire account. Reviewers only have access to the approvals dashboard.",
      componentId: "Select",
      section: "Permissions",
      orientation: "horizontal",
      width: "200px",
      options: [
        {
          label: "Admin",
          value: "admin",
        },
        {
          label: "Reviewer",
          value: "reviewer",
        },
      ],
      displayCondition: () => !isAccountOwner,
    },
    {
      id: "session_visibility",
      label: "Session Visibility",
      hint: "Determines what sessions this user can see",
      section: "Permissions",
      orientation: "horizontal",
      width: "200px",
      componentId: "Select",
      options: [
        {
          label: "All Sessions",
          value: "all",
        },
        {
          label: "Assigned Sessions Only",
          value: "assigned",
        },
      ],
      displayCondition: () => !isAccountOwner,
    },
  ];

  // Hiding for now because it doesn't actually do anything
  // {
  //   id: "role",
  //   label: "Custom Role",
  //   hint: "Define a custom role that can be used to control permissions",
  //   placeholder: "Admin, Editor, Project Manager, etc.",
  // },

  const activeUserId = get(activeUser, "id");

  // Handle new user fields
  if (!activeUserId) {
    fields = [...fields];
  }

  return (
    <>
      {activeUser && (
        <Modal
          minWidth="500px"
          header={{
            title: activeUserId ? `Edit User: ${activeUser.email}` : "Add User",
          }}
          hide={() => setActiveUser(null)}
        >
          <Form
            sectionPadding={"20px 0 0 0"}
            firstSectionPadding={"0 0 15px 0"}
            fields={fields.filter(
              (f) =>
                !f.displayCondition ||
                (f.displayCondition && f.displayCondition())
            )}
            onChange={(k, v) => setActiveUser({ ...activeUser, [k]: v })}
            submit={saveUser}
            submitText="Save Changes"
            value={activeUser}
            isFetching={savingUser}
            buttons={
              activeUserId && [
                {
                  text: "Remove From Workspace",
                  onClick: () =>
                    setConfirmationModalData({
                      title: "Remove User",
                      text: "Are you sure you want to remove this user from your workspace? They will lose access.",
                      confirmText: "Remove",
                      cancelText: "Cancel",
                      confirm: () => deleteUser("remove_from_workspace"),
                    }),
                  type: "basic",
                },
                {
                  text: "Delete",
                  onClick: () =>
                    setConfirmationModalData({
                      title: "Delete User",
                      text: "Are you sure you want to delete this user? This is irreversible.",
                      confirmText: "Delete",
                      cancelText: "Cancel",
                      confirm: () => deleteUser("delete"),
                    }),
                  type: "basic",
                },
              ]
            }
          />
        </Modal>
      )}
      <AdminWrapper
        title="Users"
        description="Manage the users who can access your account."
        buttons={[
          {
            text: "Add User",
            size: "large",
            icon: "FiPlus",
            onClick: () =>
              setActiveUser({
                role: "reviewer",
                session_visibility: "all",
              }),
          },
        ]}
      >
        {loading ? (
          <Spinner />
        ) : (
          <Container>
            <Table
              data={{
                noRecordsMessage: "No users match these filters",
                rows: users.map((u) => ({
                  ...u,
                  name: `${u.first_name} ${u.last_name}`,
                  role: u.admin ? "Owner" : startCase(u.role),
                  session_visibility: startCase(u.session_visibility),
                })),
                columns: [
                  { id: "name", fontWeight: 400 },
                  { id: "email" },
                  { id: "role" },
                  { id: "session_visibility" },
                ],
                onRowClick: (i) => setActiveUser(get(users, i, null)),
              }}
            />
          </Container>
        )}
      </AdminWrapper>
    </>
  );
};

export default Users;

const Container = styled.div`
  background: white;
  border-radius: 10px;
  width: 100%;
  margin-top: 10px;
`;
