import { Dropdown, Form, Modal, Text } from "components";
import { apiRequest, webUrl } from "utils/apiRequests";
import { get, startCase } from "lodash";
import { rAccount, rUser, rWorkspaces } from "utils/recoil";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import Cookies from "js-cookie";
import colors from "utils/colors";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";
import { successNotification } from "utils/notification";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const AccountMenu = () => {
  const [creating, setCreating] = useState(false);
  const [switching, setSwitching] = useState(false);
  const [createWorkspaceObj, setCreateWorkspaceObj] = useState(null);
  const [switchWorkspaceObj, setSwitchWorkspaceObj] = useState(false);

  const [account, setAccount] = useRecoilState(rAccount);

  const user = useRecoilValue(rUser);

  const workspaces = useRecoilValue(rWorkspaces);

  const userName = user ? `${user.first_name} ${user.last_name}` : "";

  const currentAccountFirstLetter = startCase(get(account, ["name", 0], ""));

  const setUser = useSetRecoilState(rUser);

  const navigate = useNavigate();

  // Create a new workspace
  const createWorkspace = () => {
    setCreating(true);
    apiRequest.post("/create_workspace/", createWorkspaceObj).then((r) => {
      const res = get(r, "data");
      const accountRes = get(res, "account");
      setAccount(accountRes);
      setCreating(false);
      setCreateWorkspaceObj(null);
      successNotification("Workspace Created");
      Cookies.set("curatorWorkspaceId", get(accountRes, "id"));
      window.location.href = `${webUrl}/admin/flows`;
    });
  };

  // Switch active workspace
  const switchWorkspace = () => {
    setSwitching(true);
    Cookies.set("curatorWorkspaceId", get(switchWorkspaceObj, "workspace"));
    // Refresh the page in browser
    window.location.href = `${webUrl}/admin/flows`;
  };

  return (
    <>
      {switchWorkspaceObj && (
        <Modal
          minWidth="450px"
          header={{ title: "Switch Workspace" }}
          hide={() => setSwitchWorkspaceObj(false)}
          buttons={[
            {
              text: "Create New Workspace",
              onClick: switchWorkspace,
              icon: "FiPlus",
              type: "basic",
              onClick: () => {
                setSwitchWorkspaceObj(false);
                setCreateWorkspaceObj(true);
              },
            },
            {
              text: "Switch Workspace",
              onClick: switchWorkspace,
              isFetching: switching,
              icon: "FiArrowRight",
              flippedIcon: true,
              disabled: !get(switchWorkspaceObj, "workspace"),
            },
          ]}
        >
          <Form
            value={switchWorkspaceObj}
            submitText="Switch Workspace"
            fields={[
              {
                id: "workspace",
                label: "Workspace",
                componentId: "Select",
                options: workspaces.map((w) => ({
                  label: w.name,
                  value: w.id,
                })),
              },
            ]}
            onChange={(k, v) => {
              setSwitchWorkspaceObj({ ...switchWorkspaceObj, [k]: v });
            }}
          />
        </Modal>
      )}
      {createWorkspaceObj && (
        <Modal
          header={{ title: "Create New Workspace" }}
          hide={() => setCreateWorkspaceObj(null)}
          buttons={[
            {
              text: "Select Existing Workspace",
              onClick: () => {
                setSwitchWorkspaceObj({});
                setCreateWorkspaceObj(null);
              },
              icon: "FiRefreshCcw",
              type: "basic",
              flippedIcon: true,
            },
            {
              text: "Create New Workspace",
              onClick: createWorkspace,
              isFetching: creating,
              disabled: !get(createWorkspaceObj, "name"),
              icon: "FiPlus",
            },
          ]}
        >
          <Form
            value={createWorkspaceObj}
            fields={[
              {
                id: "name",
                label: "Name",
                componentId: "Input",
                hint: "This name will be used to identify your account and will be used in email notifications to your users.",
              },
              {
                id: "logo",
                label: "Logo",
                componentId: "ImageUpload",
              },
              {
                id: "primary_color",
                label: "Primary Color",
                componentId: "ColorPicker",
                orientation: "horizontal",
              },
            ]}
            onChange={(k, v) => {
              setCreateWorkspaceObj({ ...createWorkspaceObj, [k]: v });
            }}
          />
        </Modal>
      )}

      <Dropdown
        data={{
          component: (
            <CustomMenuButton>
              <CustomMenuIcon>{currentAccountFirstLetter}</CustomMenuIcon>
              <div>
                <Text
                  data={{
                    characterLimit: 16,
                    text: get(account, "name"),
                    fontSize: 16,
                    fontWeight: 700,
                    cursor: "pointer",
                  }}
                />
                <Text
                  data={{
                    text: userName,
                    fontSize: 14,
                    cursor: "pointer",
                    color: "rgba(0,0,0,0.6)",
                  }}
                />
              </div>
            </CustomMenuButton>
          ),
          options: [
            {
              label: "Switch Workspace",
              onClick: () => setSwitchWorkspaceObj(true),
              icon: "FiRefreshCcw",
            },
            {
              label: "Create New Workspace",
              onClick: () => setCreateWorkspaceObj({}),
              icon: "FiPlus",
            },
            {
              label: "Logout",
              icon: "FiLogOut",
              onClick: () => {
                Cookies.remove("accessToken");
                navigate("/login?logout=true");
                setUser(null);
                mixpanel.reset();
              },
            },
          ],
        }}
      />
    </>
  );
};

export default AccountMenu;

const CustomMenuButton = styled.div`
  border-bottom: 1px solid ${colors.inputBorder};
  padding: 5px 5px 10px 14px;
  cursor: pointer;
  align-items: center;
  display: flex;
  gap: 10px;
  &:hover {
    background: rgba(0, 0, 0, 0.02);
  }
`;

const CustomMenuIcon = styled.div`
  padding: 10px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.3);
  height: 32px;
  width: 32px;
  color: white;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
`;
