import { Dropdown, Icon, Text } from "components";
import { rAccount, rUser } from "utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import Cookies from "js-cookie";
import MadeWith from "components/MadeWith";
import colors from "utils/colors";
import curatorLogo from "images/curator-green.png";
import { get } from "lodash";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const LiveWrapper = ({ children }) => {
  const account = useRecoilValue(rAccount);
  const logo = get(account, "logo") || curatorLogo;
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(rUser);

  const userName = `${get(user, "first_name")} ${get(user, "last_name")}`;

  const dropdownComponent = (
    <UserRow>
      <Icon
        data={{
          icon: "FiUser",
          size: 20,
          color: "rgba(0, 0, 0, 0.5)",
          hover: true,
        }}
      />
      <UserName>{userName}</UserName>
    </UserRow>
  );

  return (
    <Container>
      <MadeWith />
      <Header>
        {logo && (
          <Logo
            src={logo}
            style={{
              cursor: get(user, "admin", false) ? "pointer" : "default",
            }}
            onClick={() =>
              get(user, "admin", false)
                ? (window.location.href = "/admin/flows")
                : null
            }
          />
        )}
        {user && (
          <Dropdown
            data={{
              component: dropdownComponent,
              options: [
                {
                  label: "Logout",
                  onClick: () => {
                    Cookies.remove("accessToken");
                    navigate("/login?logout=true");
                    setUser(null);
                    mixpanel.reset();
                  },
                },
              ],
            }}
          />
        )}
      </Header>
      <Body>
        <Content>{children}</Content>
      </Body>
    </Container>
  );
};

export default LiveWrapper;

const UserRow = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid ${colors.inputBorder};
  border-radius: 8px;
  padding: 10px;
  gap: 10px;
  cursor: pointer;
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--live-background);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  width: 100%;
  border-bottom: 1px solid ${colors.inputBorder};
  padding: 10px 25px;
  height: 60px;
  @media (max-width: 800px) {
    padding: 10px 15px;
  }
`;

const Logo = styled.img`
  height: 42px;
  width: 150px;
  object-fit: contain;
  object-position: left;
`;

const Body = styled.div`
  overflow-y: auto;
  height: 100%;
  height: calc(100% - 60px);
`;

const Content = styled.div`
  padding: 50px;
  margin: 0 auto;
  max-width: 1200px;
  @media (max-width: 800px) {
    padding: 15px;
  }
`;

const UserName = styled.div`
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  @media (max-width: 800px) {
    display: none;
  }
`;
