import { Button, Form, Row, Text } from "components";
import { errorNotification, successNotification } from "utils/notification";
import { get, isEmpty } from "lodash";
import { useEffect, useState } from "react";

import AdminWrapper from "components/AdminWrapper";
import { apiRequest } from "utils/apiRequests";
import colors from "utils/colors";
import { getUrlParameter } from "utils/utils";
import { rAccount } from "utils/recoil";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import useMixpanel from "utils/useMixpanel";
import { useRecoilState } from "recoil";

const Settings = () => {
  // Track mixpanel
  const { track } = useMixpanel();
  useEffect(() => {
    track("Settings");
  }, []);

  const [account, setAccount] = useRecoilState(rAccount);
  const [accountChanges, setAccountChanges] = useState({});
  const [automationPlatformChanges, setAutomationPlatformChanges] =
    useState(false);
  const [showOtherPlatform, setShowOtherPlatform] = useState(false);
  const [slackFetched, setSlackFetched] = useState(false);
  const [passwordData, setPasswordData] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });

  const save = () => {
    apiRequest.post("/account/", accountChanges).then((res) => {
      successNotification("Saved");
      setAccountChanges({});
    });
  };

  const updateAutomationPlatform = () => {
    apiRequest
      .post("/account/", {
        automation_platform: get(account, "automation_platform", ""),
      })
      .then(() => {
        successNotification("Automation platform updated");
        setAutomationPlatformChanges(false);
      });
  };

  const location = useLocation();

  const slackCode = getUrlParameter("code", location);

  // TODO - Make real
  // const slackState = getUrlParameter("state", location);
  const slackState = "testing12341234";

  useEffect(() => {
    if (!slackFetched && slackCode && slackState && get(account, "id")) {
      setSlackFetched(true);
      apiRequest
        .post("/slack/auth/", { code: slackCode, state: slackState })
        .then((res) => {
          const success = get(res, ["data", "success"], false);
          if (success) {
            successNotification("Slack connected");
            setAccount({
              ...account,
              slack_integrated: true,
            });
          } else {
            errorNotification("Failed to connect to Slack");
          }
        });
    }
  }, [account, slackCode, slackState, slackFetched]);

  const slackIntegrated = get(account, "slack_integrated", false);

  const updatePassword = () => {
    if (passwordData.newPassword !== passwordData.confirmNewPassword) {
      errorNotification("Passwords do not match");
      return;
    }

    apiRequest
      .post("/reset_password/", { password: passwordData.newPassword })
      .then(() => {
        successNotification("Password updated successfully");
        setPasswordData({ newPassword: "", confirmNewPassword: "" });
      })
      .catch(() => {
        errorNotification("Failed to update password");
      });
  };

  return (
    <AdminWrapper title="Workspace Settings">
      <Row $gap={30} $wrap="wrap">
        <Container>
          <Text
            data={{
              text: "Branding",
              fontSize: 22,
              fontWeight: 600,
              margin: "0 0 20px 0",
            }}
          />
          <Form
            value={account}
            submitText="Save Changes"
            submit={isEmpty(accountChanges) ? null : save}
            fields={[
              {
                id: "name",
                label: "Name",
                componentId: "Input",
                value: get(account, "name", ""),
                hint: "This name will be used to identify your account and will be used in email notifications to your users.",
              },
              {
                id: "logo",
                label: "Logo",
                componentId: "ImageUpload",
                value: get(account, "logo", ""),
              },
              {
                id: "primary_color",
                label: "Primary Color",
                componentId: "ColorPicker",
                value: get(account, "primary_color", ""),
                orientation: "horizontal",
              },
              {
                id: "background_color",
                label: "Background Color",
                componentId: "ColorPicker",
                value: get(account, "background_color", ""),
                orientation: "horizontal",
              },
            ]}
            onChange={(k, v) => {
              setAccount({ ...account, [k]: v });
              setAccountChanges({ ...accountChanges, [k]: v });
            }}
          />
        </Container>

        <Container>
          <Text
            data={{
              text: "API Key",
              fontSize: 22,
              fontWeight: 600,
              margin: "0 0 10px 0",
            }}
          />
          <Text
            data={{
              text: "Your API Key is required in your requests to the 'Create Session' endpoint.",
              fontSize: 16,
              fontWeight: 300,
              margin: "0 0 20px 0",
              allowSelect: true,
            }}
          />
          <Text
            data={{
              text: "Click to copy:",
              fontSize: 16,
              fontWeight: 300,
              margin: "0 0 5px 0",
              allowSelect: true,
            }}
          />
          <Text
            data={{
              text: get(account, "api_key", ""),
              fontSize: 18,
              fontWeight: 500,
              color: colors.primary,
              allowSelect: true,
              onClick: () => {
                navigator.clipboard.writeText(get(account, "api_key", ""));
                successNotification("Copied to clipboard");
              },
            }}
          />
        </Container>

        <Container>
          <Text
            data={{
              text: "Custom Domain",
              fontSize: 22,
              fontWeight: 600,
              margin: "0 0 10px 0",
            }}
          />
          <Text
            data={{
              text: "Adding a custom domain incurs an extra fee. Contact support@curatorapp.ai to learn more.",
              fontSize: 16,
              fontWeight: 300,
            }}
          />
        </Container>

        <Container>
          <Text
            data={{
              text: "Slack Integration",
              fontSize: 22,
              fontWeight: 600,
              margin: "0 0 10px 0",
            }}
          />
          <Text
            data={{
              text: "Add a Slack integration to your account to receive notifications when a session is created.",
              fontSize: 16,
              fontWeight: 300,
            }}
          />
          {!slackIntegrated && (
            <Button
              data={{
                text: "Add Slack Integration",
                margin: "20px 0 0 0",
                icon: "FiSlack",
                onClick: () => {
                  const clientId = process.env.REACT_APP_SLACK_CLIENT_ID;
                  const redirectUrl = `https://app.curatorapp.ai/admin/settings/?action=slack_confirm`;

                  // Auth scopes
                  const scopes = [
                    "channels:read",
                    "users:read",
                    "chat:write",
                    "im:write",
                    "mpim:write",
                    "groups:write",
                  ].join(",");

                  window.open(
                    `https://slack.com/oauth/v2/authorize?client_id=${clientId}&scope=${scopes}&redirect_uri=${redirectUrl}`,
                    "_blank"
                  );
                },
              }}
            />
          )}
          {slackIntegrated && (
            <Button
              data={{
                text: "Disconnect Slack",
                margin: "20px 0 0 0",
                onClick: () => {
                  apiRequest.post("/account/", {
                    slack_connection: null,
                  });
                  successNotification("Slack disconnected");
                  setAccount({
                    ...account,
                    slack_integrated: false,
                  });
                },
              }}
            />
          )}
        </Container>

        <Container>
          <Text
            data={{
              text: "Password",
              fontSize: 22,
              fontWeight: 600,
              margin: "0 0 20px 0",
            }}
          />
          <Form
            onChange={(k, v) => {
              setPasswordData({ ...passwordData, [k]: v });
            }}
            value={passwordData}
            fields={[
              {
                id: "newPassword",
                label: "New Password",
                componentId: "Input",
                type: "password",
                orientation: "horizontal",
                width: "250px",
              },
              {
                id: "confirmNewPassword",
                label: "Confirm New Password",
                componentId: "Input",
                type: "password",
                orientation: "horizontal",
                width: "250px",
              },
            ]}
            submit={updatePassword}
            submitText="Update Password"
          />
        </Container>
        <Container>
          <Text
            data={{
              text: "Automation Platform",
              fontSize: 22,
              fontWeight: 600,
              margin: "0 0 10px 0",
            }}
          />
          <Text
            data={{
              text: "Select your automation platform. This affects how some features work in the app.",
              fontSize: 16,
              fontWeight: 300,
              margin: "0 0 20px 0",
            }}
          />
          <Form
            onChange={(k, v) => {
              setAccount({ ...account, automation_platform: v });
              setAutomationPlatformChanges(true);
            }}
            fields={[
              {
                id: "automation_platform",
                label: "Automation Platform",
                componentId: showOtherPlatform ? "Input" : "Select",
                value: get(account, "automation_platform", ""),
                options: [
                  "Zapier",
                  "Make",
                  "N8n",
                  "ActivePieces",
                  "LateNode",
                  "Albato",
                ].map((p) => ({
                  label: p,
                  value: p,
                })),
              },
            ]}
          />

          {showOtherPlatform && (
            <Text
              data={{
                text: "Show all platforms",
                fontSize: 16,
                fontWeight: 400,
                margin: "10px 0 20px 0",
                color: colors.primary,
                onClick: () => {
                  setShowOtherPlatform(false);
                },
              }}
            />
          )}
          {!showOtherPlatform && (
            <Text
              data={{
                text: "My platform is not listed",
                fontSize: 16,
                fontWeight: 400,
                margin: "10px 0 20px 0",
                color: colors.primary,
                onClick: () => {
                  setShowOtherPlatform(true);
                  setAutomationPlatformChanges(true);
                  setAccount({
                    ...account,
                    automation_platform: "Other",
                  });
                },
              }}
            />
          )}

          {automationPlatformChanges && (
            <Button
              data={{
                margin: "15px 0 0 0",
                text: "Save Changes",
                onClick: updateAutomationPlatform,
              }}
            />
          )}
        </Container>
      </Row>
    </AdminWrapper>
  );
};

export default Settings;

const Container = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid ${colors.inputBorder};
  width: 31.5%;
  align-self: stretch;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;
