import "./index.css";

import * as Sentry from "@sentry/react";

import App from "App";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import { RecoilRoot } from "recoil";
import { createRoot } from "react-dom/client";

if (!window.location.host.includes("localhost")) {
  Sentry.init({
    dsn: "https://8048121a4998bd5ab0479346e8971791@o4508620216664069.ingest.us.sentry.io/4508620621348864",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      // "localhost", /^https:\/\/yourserver\.io\/api/
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <RecoilRoot>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </RecoilRoot>
);
