import { Icon, Menu, Row, SearchBar } from ".";
import { get, startCase } from "lodash";
import { getFieldStyles, safeArray, safeLower, safeString } from "utils/utils";

import styled from "styled-components";
import { useState } from "react";

const Select = ({ data }) => {
  const { onChange } = data;

  const options = safeArray(get(data, "options", []));
  const validTypes = ["text", "number", "password", "email"];

  // Single or Multi
  const isMulti = get(data, "multi", false);
  // How we store multi-values (csv or array)
  const multiType = get(data, "multiType", "array");

  // Original incoming value
  let rawValue = get(data, "value") || get(data, "defaultValue") || "";

  /**
   * Convert the incoming value to a normalized form.
   * - If single-select, return as-is (string or value).
   * - If multiType === "csv", parse the CSV into a trimmed array.
   * - Otherwise (multiType === "array"), ensure we always have an array.
   */
  const parseValue = (val) => {
    if (!isMulti) return val; // single-select, no transformation

    if (multiType === "csv") {
      return safeString(val)
        .split(",")
        .map((item) => item.trim()) // trim spaces
        .filter(Boolean); // remove empty
    } else {
      // array mode
      return Array.isArray(val) ? val : safeArray(val);
    }
  };

  /**
   * Convert our internal array back into the final format
   * before calling onChange.
   * - If single-select, return as-is.
   * - If multiType === "csv", join array with commas (a,b,c).
   * - Otherwise (multiType === "array"), pass an array.
   */
  const formatValue = (val) => {
    if (!isMulti) return val;

    if (multiType === "csv") {
      return safeArray(val).filter(Boolean).join(",");
    } else {
      // array mode
      return safeArray(val);
    }
  };

  // Internal normalized value
  const value = parseValue(rawValue);

  const selectText =
    get(data, "selectText") ||
    data.placeholder ||
    data.noneText ||
    `Select ${safeLower(startCase(get(data, "key")))}`;

  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState("");

  const showSearch = get(data, "showSearch", false);

  // Filter options by search text
  const filteredOptions = options.filter(
    (o) =>
      get(o, "label", "").toLowerCase().includes(search.toLowerCase()) ||
      get(o, "value", "").toLowerCase().includes(search.toLowerCase())
  );

  // Which options are selected based on `value` (array if multi)
  const selectedOptions = options.filter((o) =>
    isMulti ? value.includes(safeString(o.value)) : o.value == value
  );

  const getDisplayValue = () => {
    let displayValue = selectText;

    if (selectedOptions.length) {
      if (selectedOptions.length > 2) {
        return `${selectedOptions.length} selected`;
      }

      displayValue = selectedOptions.map((o) => o.label).join(", ");
    }

    return displayValue;
  };

  const displayValue = getDisplayValue();

  // If single-select with exactly one selection that has an icon, display that icon
  const icon =
    selectedOptions.length === 1 ? get(selectedOptions[0], "icon") : null;

  // Handle user toggling an option
  const handleOptionClick = (optionValue) => {
    let newValue;

    if (isMulti) {
      const optionString = safeString(optionValue);

      // If the option already exists in array, remove it; otherwise, add it
      if (value.includes(optionString)) {
        // Remove it
        newValue = value.filter((v) => v !== optionString);
      } else {
        // Add it (ensuring no duplicates)
        newValue = [...new Set([...value, optionString])];
      }
    } else {
      // Single select
      newValue = optionValue;
    }

    // Pass the correctly formatted value to onChange
    onChange(formatValue(newValue));

    // Close menu after choosing an item in single-select mode
    if (!isMulti) {
      setAnchorEl(null);
    }
  };

  return (
    <>
      <Menu
        anchorElement={anchorEl}
        hide={() => setAnchorEl(null)}
        padding="0px"
        minWidth="200px"
      >
        {showSearch && (
          <SearchBar
            data={{
              value: search,
              margin: "10px 10px 0 10px",
              placeholder: "Search operators",
              onChange: (v) => setSearch(v),
              setupMode: false,
            }}
          />
        )}
        <MenuItems>
          {filteredOptions.map((o) => (
            <MenuItem
              key={o.value}
              $active={isMulti ? value.includes(o.value) : o.value === value}
              onClick={() => handleOptionClick(o.value)}
            >
              {o.icon && (
                <Icon
                  data={{
                    icon: o.icon,
                    size: 18,
                    color: "var(--light-grey)",
                    margin: "2px 0 0 0",
                    hover: true,
                  }}
                />
              )}
              {o.label}
            </MenuItem>
          ))}
          {filteredOptions.length === 0 && (
            <MenuItem>No results found</MenuItem>
          )}
        </MenuItems>
      </Menu>

      {/* Main "select" display container */}
      <Container
        {...data}
        type={validTypes.includes(data.type) ? data.type : "text"}
        width={data.width || "100%"}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setAnchorEl(e.currentTarget);
        }}
      >
        <Row $alignitems="center" $gap="7px">
          {icon && (
            <Icon
              data={{
                icon,
                size: 20,
                color: "var(--light-grey)",
                margin: "2px 0 0 0",
                hover: true,
              }}
            />
          )}
          <ValueText
            value={value}
            fontSize={data.fontSize}
            fontWeight={data.fontWeight}
            color={data.color}
          >
            {displayValue}
          </ValueText>
        </Row>
        <Icon
          data={{
            icon: anchorEl ? "FiChevronUp" : "FiChevronDown",
            size: 20,
            color: "var(--light-grey)",
            margin: "3px 0 0 0",
            hover: true,
          }}
        />
      </Container>
    </>
  );
};

export default Select;

/* ------------------------------ STYLES ------------------------------ */

const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
  padding: 8px 0 8px 0;
`;

const MenuItem = styled.div`
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  color: var(--text-color);
  background: ${(p) => (p.$active ? "var(--divider)" : "transparent")};

  &:hover {
    background: var(--divider);
  }
`;

const ValueText = styled.div`
  font-size: ${(p) => p.fontSize};
  font-weight: ${(p) => p.fontWeight};
  /* If no selections, show placeholder color; if selected, show user-defined color */
  color: ${(p) => (p.value ? p.color : "var(--light-grey)")};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(p) => getFieldStyles(p)};
  width: ${(p) => p.width};
  text-align: left;
  min-width: 50px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;
