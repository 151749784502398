import { ThemeProvider, createTheme } from "@mui/material/styles";

import { Pagination as MuiPagination } from "@mui/material";
import { get } from "lodash";
import { rAccount } from "utils/recoil";
import { useRecoilValue } from "recoil";

const Pagination = ({ data }) => {
  const { page = 1, pageCount = 1, onChange } = data;

  const account = useRecoilValue(rAccount);
  const primaryColor = get(account, "primary_color", "#014440");

  const theme = createTheme({
    palette: {
      primary: {
        main: primaryColor,
      },
    },
  });

  if (pageCount === 1) {
    return <></>;
  }

  return (
    <div style={{ margin: "15px 0 0 0" }}>
      <ThemeProvider theme={theme}>
        <MuiPagination
          className="mui-pagination"
          variant="outlined"
          shape="rounded"
          color="primary"
          page={page}
          count={pageCount}
          onChange={(e, p) => onChange(p)}
          hideNextButton={false}
          hidePrevButton={false}
        />
      </ThemeProvider>
    </div>
  );
};

export default Pagination;
