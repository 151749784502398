import TextareaAutosize from "react-textarea-autosize";
import { getPixels } from "utils/utils";
import styled from "styled-components";

const EditableText = ({
  onChange,
  value = "",
  placeholder = "",
  editable = false,
  fontSize = 16,
  fontWeight = 400,
  minWidth = null,
  multiline = false,
}) => {
  if (editable) {
    if (multiline) {
      return (
        <StyledTextArea
          value={value}
          fontSize={fontSize}
          fontWeight={fontWeight}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          minWidth={minWidth}
        />
      );
    }

    return (
      <StyledInput
        value={value}
        fontSize={fontSize}
        fontWeight={fontWeight}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        minWidth={minWidth}
      />
    );
  }

  return (
    <StyledDiv fontSize={fontSize} fontWeight={fontWeight}>
      {value}
    </StyledDiv>
  );
};

export default EditableText;

const StyledTextArea = styled(TextareaAutosize)`
  min-width: ${({ minWidth }) => minWidth || "200px"};
  padding: 0px;
  border: 1px solid transparent;
  border-radius: 3px;
  font-size: ${({ fontSize }) => getPixels(fontSize)};
  font-weight: ${({ fontWeight }) => fontWeight};
  background: transparent;
  color: var(--text-color);
  width: 100%;
  outline: none;
  resize: none;
  &:hover {
    background: var(--divider);
  }
`;

const StyledInput = styled.input`
  min-width: ${({ minWidth }) => minWidth || "200px"};
  padding: 0px;
  border: 1px solid transparent;
  border-radius: 3px;
  font-size: ${({ fontSize }) => getPixels(fontSize)};
  font-weight: ${({ fontWeight }) => fontWeight};
  background: transparent;
  color: var(--text-color);
  width: 100%;
  outline: none;
  resize: none;
  &:hover {
    background: var(--divider);
  }
`;

const StyledDiv = styled.div`
  font-size: ${({ fontSize }) => getPixels(fontSize)};
  font-weight: ${({ fontWeight }) => fontWeight};
  width: 100%;
  color: var(--text-color);
  cursor: default;
`;
