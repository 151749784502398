import {
  Breadcrumb,
  Button,
  Dropdown,
  Form,
  FormInputWrapper,
  Hint,
  Icon,
  Loader,
  Modal,
  Row,
  SelectToggle,
  Text,
} from "components";
import FormFields, { FieldMenu } from "components/FormFields";
import {
  convertJsonToSchema,
  getPixels,
  isValidJson,
  safeArray,
  sortFields,
} from "utils/utils";
import { errorNotification, successNotification } from "utils/notification";
import { get, isEmpty, startCase } from "lodash";
import {
  rAccount,
  rConfirmationModalData,
  rFlow,
  rFlowChangeObject,
  rSession,
  rUpdateFlow,
  rUser,
} from "utils/recoil";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import Cookies from "js-cookie";
import HierarchyMenu from "components/HierarchyMenu";
import HierarchyVisualization from "components/HierarchyVisualization";
import { SessionContent } from "pages/Sessions/Session/SessionContent";
import SetupInstructions from "./SetupInstructions";
import SlackSection from "./SlackSection";
import { apiRequest } from "utils/apiRequests";
import colors from "utils/colors";
import curatorIcon from "images/curator-icon-green.png";
import styled from "styled-components";
import useMixpanel from "utils/useMixpanel";

const SettingsSection = styled.div`
  // padding: 15px;
  overflow-x: auto;
`;

const BackSection = styled.div`
  height: 60px;
  border-right: 1px solid ${colors.inputBorder};
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 15px;
  margin-right: 15px;
`;

const CuratorIcon = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

const FlowDetails = () => {
  const user = useRecoilValue(rUser);
  const account = useRecoilValue(rAccount);

  const contexts = get(account, "contexts", []);

  const [flow, setFlow] = useRecoilState(rFlow);

  // This is only used here for the 'mock data' to show in the admin
  const setSession = useSetRecoilState(rSession);

  const [isSaving, setIsSaving] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [changeObj, setChangeObj] = useRecoilState(rFlowChangeObject);
  const [manualSessionData, setManualSessionData] = useState(null);
  const [editActionType, setEditActionType] = useState(null);
  const [tab, setTab] = useState("basics");

  const [showSetupInstructions, setShowSetupInstructions] = useState(false);

  const automationPlatform = get(account, "automation_platform");

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const actionData = get(flow, editActionType);

  const [clearedSetupInstructionsState, setClearedSetupInstructionsState] =
    useState(false);

  const clearedSetupInstructions =
    Cookies.get("clearedSetupInstructions") || clearedSetupInstructionsState;

  const { id } = useParams();

  // Track mixpanel
  const { track } = useMixpanel();
  useEffect(() => {
    track("Flow Details", {
      flow_id: id,
    });
  }, [id]);

  const navigate = useNavigate();

  const updateFlow = useSetRecoilState(rUpdateFlow);

  const save = async () => {
    setIsSaving(true);
    await apiRequest.post("/flow/", { ...changeObj, id });
    setIsSaving(false);
    successNotification("Changes saved");
    setChangeObj({});
  };

  const deleteFlow = () => {
    setIsFetching(true);
    apiRequest.delete("/flow/", { data: { id } }).then(() => {
      successNotification("Flow deleted");
      navigate("/admin/flows");
      setIsFetching(false);
    });
  };

  const getFlow = async () => {
    setIsFetching(true);
    const flowRes = await apiRequest.get(`/flow/?id=${id}`);

    setFlow(get(flowRes, "data", {}));

    const resMockData = get(flowRes, ["data", "data"], {});
    setSession({
      data: resMockData,
    });

    setIsFetching(false);
  };

  useEffect(() => {
    if (user && !flow) {
      getFlow();
    }
  }, [user]);

  const refreshData = async () => {
    setIsRefreshing(true);
    const flowRes = await apiRequest.get(`/flow/?id=${id}`);
    const newFlowConfig = get(flowRes, ["data", "config"], {});
    const newFlowData = get(flowRes, ["data", "data"], {});
    setFlow({ ...flow, config: newFlowConfig, data: newFlowData });
    setSession({ data: newFlowData });
    setIsRefreshing(false);
    successNotification("Data refreshed");
  };

  const config = get(flow, "config", {});

  const flowFields = safeArray(flow, "fields");

  const hasFields = flowFields.length > 0;

  const flowData = get(flow, "data", {}) || {};

  const schema = get(convertJsonToSchema(flowData), "keys");

  const rootSorting = get(config, ["_root", "sorting"], []);

  const sortedSchema = sortFields({
    schema,
    sortingArray: rootSorting,
    objectKey: "key",
  });

  const createManualSession = async () => {
    const jsonData = get(manualSessionData, "data_json");
    if (jsonData && !isValidJson(jsonData)) {
      errorNotification("Invalid JSON");
      return;
    }

    setIsFetching(true);

    const finalData = get(manualSessionData, "data_json") || flowData;

    const newSessionData = {
      flow_id: parseInt(id),
      api_key: get(account, "api_key"),
      title: get(manualSessionData, "title"),
      tags: get(manualSessionData, "tags"),
      overwrite_schema: get(manualSessionData, "overwrite_schema", false),
      data: finalData,
      csv_data: csvData,
      user: get(manualSessionData, "for_current_user", false)
        ? get(user, "email")
        : null,
    };

    const newSession = await apiRequest.post(
      csvData ? "/create_bulk_sessions/" : "/create_session/",
      newSessionData
    );

    const res = get(newSession, "data", {});
    setIsFetching(false);
    successNotification("Session created");

    if (!csvData) {
      const sessionLink = get(res, "session_link");
      window.open(sessionLink, "_blank");
    }
  };

  let dropdownOptions = [
    {
      label: "Delete Flow",
      icon: "FiTrash",
      onClick: () =>
        setConfirmationModalData({
          title: "Delete Flow",
          text: "Are you sure you want to delete this flow? This is irreversible.",
          confirmText: "Delete",
          cancelText: "Cancel",
          confirm: deleteFlow,
        }),
    },
  ];

  if (flowData) {
    dropdownOptions = [
      ...dropdownOptions,
      {
        label: "Create Session Manually",
        icon: "FiPlus",
        onClick: () => setManualSessionData({}),
      },
    ];
  }

  const confirmAction = get(flow, "confirm_action");
  const denyAction = get(flow, "deny_action");
  const hasAction = confirmAction || denyAction;

  // Fetching
  if (isFetching) {
    return <Loader />;
  }

  const variableFields = hasFields
    ? []
    : [
        {
          id: "data_json",
          label: "Data",
          componentId: "TextArea",
          monospace: true,
          placeholder: "Data",
          minHeight: "200px",
          hint: "The data to be used in the session. Leave blank to use the existing cached data.",
          value: get(manualSessionData, "data_json"),
        },
        {
          id: "overwrite_schema",
          label: "Overwrite Schema",
          componentId: "Switch",
          hint: "If checked, the schema will be overwritten with the new data",
          value: get(manualSessionData, "overwrite_schema", false),
        },
      ];

  const csvData = get(manualSessionData, "csv_data");

  // Render content
  return (
    <>
      {showSetupInstructions && (
        <Modal
          minWidth="800px"
          header={{
            title: "Setup Instructions",
          }}
          hide={() => setShowSetupInstructions(false)}
        >
          <SetupInstructions
            fields={safeArray(flow, "fields")}
            hasData={!isEmpty(flowData)}
            showHeader={false}
          />
        </Modal>
      )}
      {manualSessionData && (
        <Modal
          minWidth="600px"
          header={{
            title: "Create Session Manually",
            description:
              "This feature makes testing your flows easier, since you can start with a valid session and test the review and submission process.",
          }}
          buttons={[
            {
              text: csvData ? "Bulk Create Sessions" : "Create Session",
              onClick: createManualSession,
            },
          ]}
          hide={() => setManualSessionData(null)}
        >
          <Form
            borderBottom={true}
            sectionPadding={"15px"}
            fields={[
              {
                id: "title",
                label: "Session Title",
                componentId: "Input",
                placeholder: "Enter a title here",
                hint: "The visual label the user sees at the top of the session",
                value: get(manualSessionData, "title"),
              },
              {
                id: "for_current_user",
                label: "For Current User",
                componentId: "Switch",
                hint: "If checked, the session will be created for the current user",
                value: get(manualSessionData, "for_current_user", false),
              },
              {
                id: "tags",
                label: "Tags",
                componentId: "Input",
                placeholder: "Marketing, Admin, etc",
                hint: "A comma-separated list of tags, used to organize sessions in the dashboard",
                value: get(manualSessionData, "tags"),
                displayCondition: () => get(manualSessionData, "showAdvanced"),
              },
              ...variableFields,
            ].filter(
              (f) =>
                !f.displayCondition ||
                (f.displayCondition && f.displayCondition())
            )}
            onChange={(k, v) =>
              setManualSessionData({ ...manualSessionData, [k]: v })
            }
            value={manualSessionData}
          />
          {get(manualSessionData, "showAdvanced") && (
            <>
              <Text
                data={{
                  text: "Bulk-creating sessions via CSV is available by special request. Please contact support to learn more.",
                  fontSize: 16,
                  fontWeight: 500,
                  margin: "15px 0 0 0",
                }}
              />
              {/* <FormInputWrapper
                label="Bulk-create session via CSV"
                hint="Upload a CSV file with the exact field keys to bulk-create sessions"
                margin="15px 0 0 0"
              >
                <CSVUpload
                  onUpload={(parsedTableData) => {
                    setManualSessionData({
                      ...manualSessionData,
                      csv_data: parsedTableData,
                    });
                  }}
                />
              </FormInputWrapper>

              {csvData && (
                <Text
                  data={{
                    text: `${get(csvData, ["rows", "length"])} Rows Detected`,
                    fontSize: 16,
                    fontWeight: 400,
                    margin: "5px 0 0 0",
                  }}
                />
              )} */}
            </>
          )}
          {!get(manualSessionData, "showAdvanced") && (
            <Text
              data={{
                text: "Show Advanced Options",
                fontSize: 16,
                fontWeight: 500,
                margin: "20px 0 0 0",
                color: colors.primary,
                onClick: () =>
                  setManualSessionData({
                    ...manualSessionData,
                    showAdvanced: true,
                  }),
              }}
            />
          )}
        </Modal>
      )}
      <AppContainer>
        <Header>
          <BackSection>
            <CuratorIcon src={curatorIcon} onClick={() => navigate("/flows")} />
          </BackSection>
          <StyledInput
            value={get(flow, "name")}
            onChange={(e) =>
              updateFlow({
                name: e.target.value,
              })
            }
            placeholder={"New Flow"}
          />

          <Row $alignitems="center" $justifycontent="space-between" $gap="15px">
            <Dropdown
              data={{
                icon: {
                  icon: "FiMoreHorizontal",
                  size: 25,
                  color: colors.grey,
                  margin: "3px 15px 0 0",
                  hover: true,
                },
                options: dropdownOptions,
              }}
            />
            {[
              {
                text: "View Setup Instructions",
                onClick: () => setShowSetupInstructions(true),
                icon: "FiHelpCircle",
                type: "basic",
              },
              {
                text: "Refresh Data",
                onClick: refreshData,
                icon: "FiRefreshCcw",
                type: "basic",
                isFetching: isRefreshing,
              },
              {
                text: "Save Changes",
                onClick: save,
                disabled: isEmpty(changeObj),
                isFetching: isSaving,
              },
            ].map((b) => (
              <Button data={b} style={{ fontSize: "30px" }} />
            ))}
          </Row>
        </Header>
        <Sidebar>
          {editActionType && (
            <SettingsSection
              style={{
                borderBottom: `1px solid ${colors.inputBorder}`,
              }}
            >
              <Breadcrumb
                fontSize={18}
                fontWeight={500}
                margin="15px"
                items={[
                  {
                    text: "Settings",
                    onClick: () => setEditActionType(null),
                  },
                  {
                    text: startCase(editActionType),
                    onClick: null,
                  },
                ]}
              />
              <Form
                borderBottom={true}
                sectionPadding={"15px"}
                onChange={(k, v) =>
                  updateFlow({
                    [editActionType]: { ...actionData, [k]: v },
                  })
                }
                fields={[
                  {
                    id: "label",
                    label: "Button Label",
                    componentId: "Input",
                    placeholder: "Confirm",
                    value: get(actionData, "label"),
                    hint: "The text to display on the button",
                  },
                  {
                    id: "endpoint",
                    label: "Endpoint",
                    componentId: "Input",
                    placeholder: "https://your-endpoint.com/response/",
                    value: get(actionData, "endpoint"),
                    hint: "The endpoint where the final data will be sent upon submission",
                    displayCondition: () => automationPlatform !== "Zapier",
                  },
                  {
                    id: "redirect_type",
                    label: "Redirect Type",
                    componentId: "Select",
                    value: get(actionData, "redirect_type"),
                    defaultValue: "url",
                    hint: "Determines where the user will be redirected after they submit",
                    options: [
                      { label: "Dashboard", value: "dashboard" },
                      { label: "Message", value: "message" },
                      { label: "URL", value: "url" },
                      { label: "Next Approval", value: "next_session" },
                    ],
                  },
                  {
                    id: "redirect_url",
                    label: "Redirect URL",
                    componentId: "Input",
                    placeholder: "https://example.com",
                    value: get(actionData, "redirect_url"),
                    hint: "The URL to redirect to after the user submits",
                    displayCondition: () =>
                      get(actionData, "redirect_type") === "url" ||
                      !get(actionData, "redirect_type"),
                  },
                  {
                    id: "redirect_message",
                    label: "Redirect Message",
                    componentId: "Input",
                    placeholder: "Your response has been submitted",
                    value: get(actionData, "redirect_message"),
                    hint: "The message to display to the user after they submit",
                    displayCondition: () =>
                      get(actionData, "redirect_type") === "message",
                  },
                  {
                    id: "deny_result",
                    label: "Deny Result",
                    componentId: "Select",
                    value: get(actionData, "deny_result", "stop"),
                    hint: "The action to take when a session is denied",
                    options: [
                      { label: "Stop", value: "stop" },
                      { label: "Continue", value: "continue" },
                    ],
                    displayCondition: () => editActionType === "deny_action",
                  },
                  {
                    id: "clear",
                    label: "Clear Action Data (Reset)",
                    text: `Clear ${startCase(editActionType)}`,
                    type: "basic",
                    icon: "FiTrash",
                    componentId: "Button",
                    onClick: () => {
                      setConfirmationModalData({
                        title: `Clear ${startCase(editActionType)}`,
                        text: "Are you sure you want to clear this action?",
                        confirmText: "Clear",
                        cancelText: "Cancel",
                        confirm: () => {
                          setEditActionType(null);
                          updateFlow(editActionType, null);
                        },
                      });
                    },
                    displayCondition: () => !!actionData,
                  },
                ].filter(
                  (f) =>
                    !f.displayCondition ||
                    (f.displayCondition && f.displayCondition())
                )}
              />
            </SettingsSection>
          )}

          <HierarchyMenu
            originalData={flowData}
            config={config}
            onChange={(newConfig) => updateFlow("config", newConfig)}
          />

          {!editActionType && (
            <div
              style={{
                borderBottom: `1px solid ${colors.inputBorder}`,
                padding: "0 0 15px 0",
              }}
            >
              <SelectToggle
                data={{
                  margin: "15px 15px 0 15px",
                  onChange: (v) => setTab(v),
                  tabs: [
                    {
                      label: "Basics",
                      value: "basics",
                      active: !tab || tab === "basics",
                    },
                    {
                      label: "Flagging",
                      value: "flagging",
                      active: tab === "flagging",
                    },
                    {
                      label: "Advanced",
                      value: "advanced",
                      active: tab === "advanced",
                    },
                  ],
                }}
              />
            </div>
          )}

          {tab === "flagging" && (
            <SettingsSection>
              <Form
                borderBottom={true}
                sectionPadding={"15px"}
                onChange={(k, v) =>
                  updateFlow({
                    flagging_rules: {
                      ...get(flow, "flagging_rules", {}),
                      [k]: v,
                    },
                  })
                }
                fields={[
                  {
                    id: "flag_type",
                    label: "Flag Type",
                    orientation: "horizontal",
                    width: "150px",
                    section: "Flagging Settings",
                    componentId: "Select",
                    sectionHint:
                      "Define the rules that determine when a session is flagged for approval.",
                    value: get(flow, ["flagging_rules", "flag_type"], "all"),
                    hint: "The type of flagging to use",
                    options: [
                      { label: "All Sessions", value: "all" },
                      {
                        label: "AI Prompt",
                        value: "ai_prompt",
                      },
                      {
                        label: "Percentage",
                        value: "percentage",
                      },
                    ],
                  },
                  {
                    id: "flag_percentage",
                    label: "Flag Percentage",
                    section: "Flagging Settings",
                    componentId: "Input",
                    orientation: "horizontal",
                    width: "150px",
                    value: get(
                      flow,
                      ["flagging_rules", "flag_percentage"],
                      100
                    ),
                    min: 1,
                    max: 100,
                    type: "number",
                    hint: "The percentage of sessions to flag",
                    displayCondition: () =>
                      get(flow, ["flagging_rules", "flag_type"]) ===
                      "percentage",
                  },
                  {
                    id: "flag_prompt",
                    label: "AI Flagging Prompt",
                    section: "AI Flagging",
                    componentId: "TextArea",
                    minHeight: "200px",
                    value: get(flow, ["flagging_rules", "flag_prompt"]),
                    hint: "The prompt to use to flag sessions",
                    displayCondition: () =>
                      get(flow, ["flagging_rules", "flag_type"]) ===
                      "ai_prompt",
                  },
                  {
                    id: "ai_context",
                    label: "AI Context",
                    section: "AI Flagging",
                    componentId: "Select",
                    hint: "Select the relevant context items to pass in to the AI system to enhance its accuracy",
                    multi: true,
                    multiType: "array",
                    value: get(flow, ["flagging_rules", "ai_context"], []),
                    options: contexts.map((c) => ({
                      label: c.label,
                      value: c.id,
                    })),
                    displayCondition: () =>
                      get(flow, ["flagging_rules", "flag_type"]) ===
                      "ai_prompt",
                  },
                  {
                    id: "ai_model",
                    label: "AI Model",
                    orientation: "horizontal",
                    width: "150px",
                    section: "AI Flagging",
                    componentId: "Select",
                    value: get(
                      flow,
                      ["flagging_rules", "ai_model"],
                      "gpt-4o-mini"
                    ),
                    options: [
                      { label: "gpt-4o", value: "gpt-4o" },
                      { label: "gpt-4o-mini", value: "gpt-4o-mini" },
                    ],
                    displayCondition: () =>
                      get(flow, ["flagging_rules", "flag_type"]) ===
                      "ai_prompt",
                  },
                  {
                    id: "flag_false_action",
                    componentId: "Select",
                    label: "False Flag Action",
                    section: "AI Flagging",
                    orientation: "horizontal",
                    width: "150px",
                    value: get(
                      flow,
                      ["flagging_rules", "flag_false_action"],
                      "continue"
                    ),
                    hint: "The action to take when a session is not flagged",
                    options: [
                      { label: "Stop", value: "stop" },
                      { label: "Continue", value: "continue" },
                    ],
                    displayCondition: () =>
                      get(flow, ["flagging_rules", "flag_type"]) ===
                      "ai_prompt",
                  },
                ].filter(
                  (f) =>
                    !f.displayCondition ||
                    (f.displayCondition && f.displayCondition())
                )}
              />
            </SettingsSection>
          )}

          {tab === "advanced" && (
            <>
              <SettingsSection
                style={{ borderBottom: `1px solid ${colors.inputBorder}` }}
              >
                <Form
                  borderBottom={true}
                  sectionPadding={"15px"}
                  onChange={(k, v) =>
                    updateFlow({
                      [k]: v,
                    })
                  }
                  fields={[
                    {
                      id: "pageWidth",
                      label: "Page Width",
                      section: "Styling",
                      componentId: "Select",
                      orientation: "horizontal",
                      width: "150px",
                      hint: "The maximum width of the page content. Can be overridden on a per-session basis.",
                      options: [
                        { label: "600px", value: 600 },
                        { label: "800px", value: 800 },
                        { label: "1000px", value: 1000 },
                        { label: "1200px", value: 1200 },
                      ],
                      value: get(flow, "pageWidth", 800),
                    },
                    {
                      id: "maxColumnSpan",
                      label: "Max Column Span",
                      componentId: "Select",
                      section: "Styling",
                      width: "150px",
                      orientation: "horizontal",
                      hint: "The maximum number of columns to display in the field layout.",
                      options: [
                        { label: "1", value: 1 },
                        { label: "2", value: 2 },
                        { label: "3", value: 3 },
                        { label: "4", value: 4 },
                      ],
                      value: get(flow, "maxColumnSpan", 2),
                    },
                  ]}
                />
              </SettingsSection>
              {!hasFields && (
                <SettingsSection>
                  <TitleHint
                    title="Data Structure"
                    hint="Review the data structure and adjust the visualization types as needed."
                  />

                  {sortedSchema.length > 0 && (
                    <HierarchyVisualization
                      originalData={flowData}
                      data={sortedSchema}
                      config={config}
                      onChange={(newConfig) => updateFlow("config", newConfig)}
                    />
                  )}
                  {!sortedSchema.length && (
                    <Text
                      data={{
                        text: "No data structure has been established",
                        fontSize: 16,
                        fontWeight: 300,
                        margin: "5px 0 0 0",
                      }}
                    />
                  )}
                </SettingsSection>
              )}
            </>
          )}

          <FieldMenu />

          {tab === "basics" && !editActionType && (
            <>
              <SettingsSection
                style={{
                  borderBottom: `1px solid ${colors.inputBorder}`,
                  padding: "15px",
                }}
              >
                <Text
                  data={{
                    text: "Fields",
                    fontSize: 16,
                    fontWeight: 600,
                    margin: "0 0 15px 0",
                  }}
                />
                <FormFields />
              </SettingsSection>
              <SettingsSection
                style={{
                  borderBottom: `1px solid ${colors.inputBorder}`,
                  padding: "15px",
                }}
              >
                <Text
                  data={{
                    text: "Approval Notifications",
                    fontSize: 16,
                    fontWeight: 600,
                    margin: "0 0 15px 0",
                  }}
                />
                <SlackSection />
              </SettingsSection>
              <SettingsSection
                style={{
                  borderBottom: `1px solid ${colors.inputBorder}`,
                  padding: "15px",
                }}
              >
                <Text
                  data={{
                    text: "Actions",
                    fontSize: 16,
                    fontWeight: 600,
                    margin: "0 0 20px 0",
                  }}
                />

                <FormInputWrapper
                  label="Confirm Action"
                  hint="Configure the action the user will take when they confirm their data."
                >
                  <Button
                    data={{
                      text: "Configure Confirm Action",
                      type: "basic",
                      icon: "FiSettings",
                      onClick: () => setEditActionType("confirm_action"),
                      margin: "0 0 20px 0",
                    }}
                  />
                </FormInputWrapper>
                <FormInputWrapper
                  label="Deny Action"
                  hint="Configure the action the user will take when they deny their data."
                >
                  <Button
                    data={{
                      text: "Configure Deny Action",
                      type: "basic",
                      icon: "FiSettings",
                      onClick: () => setEditActionType("deny_action"),
                      margin: "0 0 5px 0",
                    }}
                  />
                </FormInputWrapper>
                {!hasAction && automationPlatform !== "Zapier" && (
                  <WarningBubble text="Configure actions to complete setup" />
                )}
              </SettingsSection>
            </>
          )}
        </Sidebar>
        <Main>
          <MainContent $maxWidth={get(flow, "pageWidth", 800)}>
            <SessionContent isAdmin={true} schema={schema} />
            {(!clearedSetupInstructions ||
              (isEmpty(flowData) && !hasFields)) && (
              <SetupInstructions
                margin="30px 0 0 0"
                fields={safeArray(flow, "fields")}
                onHide={() => {
                  Cookies.set("clearedSetupInstructions", true);
                  setClearedSetupInstructionsState(true);
                }}
              />
            )}
          </MainContent>
        </Main>
      </AppContainer>
    </>
  );
};

export default FlowDetails;

const StyledInput = styled.input`
  font-size: 26px;
  font-weight: 700;
  color: ${colors.darkGrey};
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  width: 100%;
`;

const AppContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  border-bottom: 1px solid ${colors.inputBorder};
  padding: 0 15px 0 15px;
`;

const Sidebar = styled.div`
  width: 345px;
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  border-right: 1px solid ${colors.inputBorder};
  overflow-y: auto;
`;

const Main = styled.div`
  position: fixed;
  top: 60px;
  left: 345px;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  background: var(--live-background);
`;

const MainContent = styled.div`
  max-width: ${(p) => getPixels(p.$maxWidth || 800)};
  margin: 0 auto;
  border-radius: 10px;
  padding: 30px;
`;

const TitleHint = ({ title, hint, margin = "0 0 20px 0" }) => (
  <Row $margin={margin} $alignitems="center" $gap="5px">
    <Text
      data={{
        text: title,
        fontSize: 16,
        fontWeight: 600,
      }}
    />
    <Hint hint={hint} margin="5px 0 0 0" />
  </Row>
);

const WarningBubble = ({ text, margin = "20px 0 0 0" }) => (
  <WarningBubbleBackground $margin={margin}>
    <Icon
      data={{
        icon: "FiAlertTriangle",
        size: 20,
        color: "#df516e",
      }}
    />
    <Text data={{ text, fontSize: 14 }} />
  </WarningBubbleBackground>
);

const WarningBubbleBackground = styled.div`
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid ${colors.inputBorder};
  margin: ${(p) => p.$margin};
`;
