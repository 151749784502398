import { Button, Text } from "components";
import { useLocation, useNavigate } from "react-router-dom";

import ProgressBar from "components/ProgressBar";
import colors from "utils/colors";
import { get } from "lodash";
import { getAccountTrialDays } from "utils/utils";
import moment from "moment";
import { rAccount } from "utils/recoil";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

const TrialSection = () => {
  const account = useRecoilValue(rAccount);

  const subscription = get(account, ["billing", "subscription"], null);

  const createdDate = get(account, "organization_created", null);
  const trialDuration = 14;
  const daysPassed = moment().diff(moment(createdDate), "days");

  const trialDaysRemaining = getAccountTrialDays(account);

  // Get progress percent and always show at least a few percent
  let progressPercent = (daysPassed / trialDuration) * 100;
  if (progressPercent < 5) {
    progressPercent = 3;
  }

  const navigate = useNavigate();

  const location = useLocation();
  const pathname = get(location, "pathname", "");
  const isBilling = pathname.includes("/admin/billing");

  return (
    <>
      {!isBilling && !subscription && trialDaysRemaining > 0 && (
        <TrialDays>
          <Text
            data={{
              text: `Trial ends in ${trialDaysRemaining} days`,
              fontSize: 14,
              fontWeight: 500,
              color: "#1a1a1a",
              margin: "0 0 8px 0",
            }}
          />
          <ProgressBar percent={progressPercent} />
          <Button
            data={{
              text: "Upgrade Now",
              margin: "10px 0 0 0",
              width: "100%",
              onClick: () => navigate("/admin/billing/"),
            }}
          />
        </TrialDays>
      )}
      {!isBilling && !subscription && trialDaysRemaining <= 0 && (
        <TrialDays>
          <Text
            data={{
              text: `Your trial has ended`,
              fontSize: 14,
              fontWeight: 500,
              color: "#1a1a1a",
              margin: "0 0 8px 0",
            }}
          />
          <Button
            data={{
              text: "Upgrade Now",
              margin: "10px 0 0 0",
              width: "100%",
              onClick: () => navigate("/admin/billing/"),
            }}
          />
        </TrialDays>
      )}
    </>
  );
};

export default TrialSection;

const TrialDays = styled.div`
  padding: 10px 10px 10px 10px;
  border-top: 1px solid ${colors.inputBorder};
`;
