import { Icon, Text } from "components";

import colors from "utils/colors";
import { getPixels } from "utils/utils";
import styled from "styled-components";

const getTransformedUrl = (url) => {
  if (!url) {
    return null;
  }

  if (!url.includes("youtu")) {
    return url;
  }

  // Check for short YouTube URL
  const shortUrlPattern = /https:\/\/youtu\.be\/([a-zA-Z0-9_-]+)/;
  const longUrlPattern =
    /https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/;

  let videoId;

  const shortMatch = url.match(shortUrlPattern);
  const longMatch = url.match(longUrlPattern);

  if (shortMatch) {
    videoId = shortMatch[1]; // Extract video ID from short URL
  } else if (longMatch) {
    videoId = longMatch[1]; // Extract video ID from long URL
  } else {
    return null; // Invalid URL format
  }

  // Return the transformed embed URL
  return `https://www.youtube.com/embed/${videoId}`;
};

const VideoPlayer = styled.iframe`
  width: ${({ width }) => getPixels(width) || "450px"};
  height: ${({ height }) => getPixels(height) || "300px"};
`;

const Video = ({ data }) => {
  const { height, width, value } = data;

  const isAdmin = false;

  const finalUrl = getTransformedUrl(value);

  return (
    <>
      {isAdmin && (
        <Container>
          <Icon data={{ icon: "FiYoutube", color: colors.grey3 }} />
          <Text
            data={{
              text: "Video content will render in live mode",
              fontStyle: "bodyXl",
            }}
          />
        </Container>
      )}
      {!isAdmin && value && (
        <VideoPlayer
          src={finalUrl}
          title="YouTube video player"
          frameBorder="0"
          allowfullscreen="true"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
        />
      )}
    </>
  );
};

export default Video;

const Container = styled.div`
  background: white;
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;
