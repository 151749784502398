import colors from "utils/colors";
import styled from "styled-components";

const ProgressBar = ({ percent, height = "10px" }) => {
  return (
    <Container height={height}>
      <Bar width={`${percent}%`} />
    </Container>
  );
};

export default ProgressBar;

const Container = styled.div`
  width: 100%;
  height: ${({ height }) => height};
  background: rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  overflow: hidden;
`;

const Bar = styled.div`
  width: ${(p) => p.width};
  height: 10px;
  background: ${colors.primary};
`;
