import { Button, Icon, Text } from "components";

import colors from "utils/colors";
import { get } from "lodash";
import { rAccount } from "utils/recoil";
import { safeLower } from "utils/utils";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

const SetupInstructions = ({ fields, onHide, margin, showHeader = true }) => {
  const account = useRecoilValue(rAccount);
  const { id } = useParams();

  const dataObject = fields.reduce((acc, field) => {
    acc[field.key] = "Your custom value";
    return acc;
  }, {});

  return (
    <ExpandingCard style={{ margin }} showHeader={showHeader}>
      {showHeader && (
        <HeaderSection>
          <Text
            data={{
              text: "Setup Instructions",
              fontSize: 20,
              fontWeight: 600,
              cursor: "pointer",
            }}
          />
          {fields.length > 0 && (
            <Icon
              data={{
                hover: true,
                icon: "FiX",
                onClick: onHide,
              }}
            />
          )}
        </HeaderSection>
      )}

      <ExpandedSection showHeader={showHeader}>
        <SetupByPlatform
          dataObject={dataObject}
          apiKey={get(account, "api_key")}
          stepId={id}
          platform={get(account, "automation_platform")}
        />
      </ExpandedSection>
    </ExpandingCard>
  );
};

export default SetupInstructions;

const SetupByPlatform = ({ platform, stepId, apiKey, dataObject }) => {
  const platformLower = safeLower(platform);

  if (platformLower === "zapier") {
    return (
      <>
        <Text
          data={{
            text: "1. View Zapier Setup Guide",
            fontSize: 18,
            fontWeight: 600,
            margin: "0 0 10px 0",
          }}
        />
        <Button
          data={{
            text: "View Zapier Setup Guide",
            variant: "primary",
            size: "large",
            icon: "FiBookOpen",
            onClick: () =>
              window.open(
                "https://intercom.help/curatorapp/en/articles/10303313-using-curator-s-zapier-integration"
              ),
          }}
        />
        <Text
          data={{
            text: "2. Add Fields To Your Approval",
            fontSize: 18,
            fontWeight: 600,
            margin: "20px 0 10px 0",
          }}
        />
        <Text
          data={{
            text: "Click 'Add Field' in the Fields section of the sidebar to manually define fields for your approval session.",
            fontSize: 18,
            fontWeight: 300,
          }}
        />
        <Text
          data={{
            text: "3. Create a Curator Step in your Zap",
            fontSize: 18,
            fontWeight: 600,
            margin: "20px 0 10px 0",
          }}
        />
        <Text
          data={{
            text: "In Zapier, create a Curator step where you want to trigger human approval.",
            fontSize: 18,
            fontWeight: 300,
          }}
        />
      </>
    );
  }
  // MAKE
  else if (platformLower === "make") {
    return (
      <>
        <Text
          data={{
            text: "1. Review the Make setup instructions",
            fontSize: 18,
            fontWeight: 600,
            margin: "0 0 10px 0",
          }}
        />
        <Button
          data={{
            text: "Review Make Setup Guide",
            variant: "primary",
            size: "large",
            icon: "FiBookOpen",
            onClick: () =>
              window.open(
                "https://intercom.help/curatorapp/en/articles/10303118-using-curator-s-make-integration"
              ),
          }}
        />
        <Text
          data={{
            text: "2. Add Fields To Your Approval",
            fontSize: 18,
            fontWeight: 600,
            margin: "20px 0 10px 0",
          }}
        />
        <Text
          data={{
            text: "Click 'Add Field' in the Fields section of the sidebar to manually define fields for your approval session.",
            fontSize: 18,
            fontWeight: 300,
          }}
        />
        <Text
          data={{
            text: "3. Add a Curator 'Create Session' step",
            fontSize: 18,
            fontWeight: 600,
            margin: "20px 0 10px 0",
          }}
        />
        <Text
          data={{
            text: "In Make, create a Curator 'Create Session' step, where you want to trigger human approval.",
            fontSize: 18,
            fontWeight: 300,
          }}
        />
        <Text
          data={{
            text: "4. Configure 'Post-Confirmation' Scenario",
            fontSize: 18,
            fontWeight: 600,
            margin: "20px 0 10px 0",
          }}
        />
        <Text
          data={{
            text: "To receive the approval session data, you need to configure the 'Post-Confirmation' scenario.",
            fontSize: 18,
            fontWeight: 300,
          }}
        />
      </>
    );
  } else {
    // OTHER

    return (
      <>
        <Text
          data={{
            text: "1. Watch Curator Demo Video",
            fontSize: 18,
            fontWeight: 600,
            margin: "0 0 10px 0",
          }}
        />
        <Button
          data={{
            text: "Watch Demo Video",
            variant: "primary",
            size: "large",
            icon: "FiPlay",
            onClick: () =>
              window.open("https://www.youtube.com/watch?v=8EN13z18TfI"),
          }}
        />
        <Text
          data={{
            text: "2. Add Fields To Your Approval",
            fontSize: 18,
            fontWeight: 600,
            margin: "20px 0 10px 0",
          }}
        />
        <Text
          data={{
            text: "Click 'Add Field' in the Fields section of the sidebar to manually define fields for your approval session.",
            fontSize: 18,
            fontWeight: 300,
          }}
        />
        <Text
          data={{
            text: "3. Trigger An Approval Session",
            fontSize: 18,
            fontWeight: 600,
            margin: "20px 0 10px 0",
          }}
        />

        <TextSpan>
          Inside your <Bold>{platform}</Bold> automation, trigger an{" "}
          <Bold>HTTP POST</Bold> request to the
          <Bold> https://api.curatorapp.ai/create_session/</Bold> endpoint with
          the following body:
        </TextSpan>
        <Structure>
          {JSON.stringify(
            {
              step_id: parseInt(stepId),
              api_key: apiKey,
              ...dataObject,
            },
            null,
            2
          )}
        </Structure>
      </>
    );
  }
};

const Bold = styled.span`
  font-weight: 600;
`;

const TextSpan = styled.span`
  font-size: 18px;
  font-weight: 300;
`;

const ExpandingCard = styled.div`
  border: 1px solid ${colors.inputBorder};
  border-radius: 10px;
  background: white;
  ${(p) => !p.showHeader && "border: none;"}
`;

const HeaderSection = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ExpandedSection = styled.div`
  padding: 20px;
  border-top: 1px solid ${colors.inputBorder};
  ${(p) => !p.showHeader && "padding: 15px 0 0 0; border-top: none;"}
`;

const Structure = styled.pre`
  font-family: monospace;
  padding: 15px;
  background: white;
  border-radius: 10px;
  font-size: 16px;
  border: 1px solid ${colors.inputBorder};
  word-wrap: break-word;
`;
