import { Button, Form, Icon, Menu, Text } from "components";
import {
  Container as DndContainer,
  Draggable,
} from "@edorivai/react-smooth-dnd";
import { arrayMove, safeArray, sortFields } from "utils/utils";
import { rAccount, rFlow, rFormFieldsState, rUpdateFlow } from "utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { basicFields } from "utils/formFields";
import colors from "utils/colors";
import { get } from "lodash";
import styled from "styled-components";

const FormFields = () => {
  const updateFlow = useSetRecoilState(rUpdateFlow);

  const step = useRecoilValue(rFlow);
  const fields = safeArray(step, "fields");

  const [formFieldsState, setFormFieldsState] =
    useRecoilState(rFormFieldsState);

  const addField = () => {
    updateFlow({
      fields: [...fields, { key: "new_field", label: "New Field" }],
    });
  };

  const onDrop = ({ addedIndex, removedIndex }) => {
    const movedFields = arrayMove(fields, removedIndex, addedIndex);
    updateFlow({
      fields: movedFields,
    });
  };

  return (
    <>
      {fields.length > 0 && (
        <DndContainer
          onDrop={onDrop}
          dragHandleSelector=".drag-item"
          lockAxis="y"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            margin: "0 0 10px 0",
          }}
        >
          {safeArray(fields).map((f, i) => (
            <Draggable key={i}>
              <Field
                field={f}
                onDelete={() => {
                  updateFlow({
                    fields: fields.filter((_, fieldIndex) => i !== fieldIndex),
                  });
                }}
                onClick={(e) =>
                  setFormFieldsState({
                    ...formFieldsState,
                    activeFieldIndex: i,
                    anchorElement: e.currentTarget,
                  })
                }
              />
            </Draggable>
          ))}
        </DndContainer>
      )}
      <Button
        data={{
          text: "Add Field",
          type: "basic",
          size: "small",
          icon: "FiPlus",
          onClick: addField,
        }}
      />
    </>
  );
};

export default FormFields;

const Field = ({ field, onClick, onDelete }) => {
  return (
    <FieldContainer onClick={onClick} className="drag-item">
      {field.label}
      <Icon
        data={{
          icon: "FiTrash",
          size: 16,
          color: "#727272",
          hover: true,
          onClick: (e) => {
            e.stopPropagation();
            onDelete();
          },
        }}
      />
    </FieldContainer>
  );
};

export const FieldMenu = () => {
  const step = useRecoilValue(rFlow);
  const updateFlow = useSetRecoilState(rUpdateFlow);
  const [formFieldsState, setFormFieldsState] =
    useRecoilState(rFormFieldsState);

  const { showAdvanced, anchorElement, activeFieldIndex } = formFieldsState;
  const fields = safeArray(step, "fields");
  const fieldConfig = get(fields, activeFieldIndex, {});
  const fieldType = get(fieldConfig, "type", "string");

  const account = useRecoilValue(rAccount);

  const maxColumnSpan = get(step, "maxColumnSpan", 2);

  const fieldKey = get(fieldConfig, "key", "");
  const [initFieldKey, setInitFieldKey] = useState(fieldKey);

  useEffect(() => {
    setInitFieldKey(fieldKey);
    setFormFieldsState({
      ...formFieldsState,
      showAdvanced: false,
    });
  }, [activeFieldIndex]);

  const formFields = basicFields({
    account,
    fieldConfig,
    fieldType,
    showAdvanced,
  });

  let finalFields = [
    {
      id: "key",
      label: "Key",
      orientation: "horizontal",
      width: "300px",
      section: "Field Settings",
      componentId: "Input",
      value: fieldKey,
      displayCondition: () => showAdvanced,
    },
    {
      id: "type",
      label: "Data Type",
      hint: "The type of data the field will store",
      componentId: "Select",
      section: "Other Settings",
      orientation: "horizontal",
      width: "150px",
      value: get(fieldConfig, "type", "string"),
      options: [
        { label: "String", value: "string" },
        { label: "Integer", value: "integer" },
        { label: "Boolean", value: "boolean" },
        // { label: "Number", value: "number" },
        // { label: "Datetime", value: "datetime" },
        // { label: "File", value: "file" },
        // { label: "Password", value: "password" },
      ],
      displayCondition: () => showAdvanced,
    },
    ...formFields,
    {
      id: "columnSpan",
      label: "Field Width",
      componentId: "Select",
      section: "Sizing",
      orientation: "horizontal",
      width: "200px",
      value: get(fieldConfig, "columnSpan", 1),
      hint: "The width of the field in the grid",
      options: [
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
        { label: "4", value: 4 },
      ].filter((o) => o.value <= maxColumnSpan),
    },
  ].filter((f) => !f.displayCondition || f.displayCondition());

  const fieldSorting = ["label", "key", "ai_generated", "ai_prompt"];

  finalFields = sortFields({
    schema: finalFields,
    sortingArray: fieldSorting,
    objectKey: "id",
  });

  const onFieldChange = (k, v) => {
    if (
      k === "label" &&
      (!initFieldKey || initFieldKey === "" || initFieldKey === "new_field")
    ) {
      const newKey = v.toLowerCase().replace(/\s+/g, "_");
      updateFlow({
        fields: fields.map((f, i) => {
          if (i === activeFieldIndex) {
            return { ...f, label: v, key: newKey };
          }
          return f;
        }),
      });
    } else {
      if (k === "key") {
        setInitFieldKey(v);
      }

      updateFlow({
        fields: fields.map((f, i) => {
          if (i === activeFieldIndex) {
            return { ...f, [k]: v };
          }
          return f;
        }),
      });
    }
  };

  return (
    <Menu
      background={"white"}
      anchorElement={anchorElement}
      hide={() =>
        setFormFieldsState({
          ...formFieldsState,
          anchorElement: null,
          activeFieldIndex: null,
        })
      }
      width={"400px"}
      padding={"0px"}
    >
      <Form
        onChange={onFieldChange}
        fields={finalFields}
        sectionPadding={"15px"}
        borderBottom={true}
      />
      <Text
        data={{
          text: showAdvanced ? "Hide advanced settings" : "View more settings",
          fontWeight: 600,
          fontSize: 15,
          cursor: "pointer",
          color: colors.primary,
          margin: "15px",
          onClick: () =>
            setFormFieldsState({
              ...formFieldsState,
              showAdvanced: !showAdvanced,
            }),
        }}
      />
    </Menu>
  );
};

const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border: 1px solid ${colors.inputBorder};
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.lightBackground};
  }
`;
