import { getPixels } from "utils/utils";
import styled from "styled-components";
import { truncate } from "lodash";

export const StyledText = styled.div`
  font-size: ${(p) => getPixels(p.fontSize)};
  font-weight: ${(p) => p.fontWeight};
  color: ${(p) => p.color || p.fontColor || "var(--text-color)"};
  padding: ${(p) => getPixels(p.padding || 0)};
  margin: ${(p) => getPixels(p.$margin || 0)};
  text-align: ${(p) => p.textAlign || "initial"};
  cursor: ${(p) => (p.onClick ? "pointer" : p.cursor || "auto")};
  text-decoration: none !important;
  line-height: ${(p) => p.lineHeight || "auto"};
  white-space: ${(p) => p.whiteSpace || "normal"};
  overflow-wrap: ${(p) => p.overflowWrap || ""};
  word-break: ${(p) => p.wordBreak || "normal"};
  letter-spacing: ${(p) => p.letterSpacing || "normal"};
  width: ${(p) => p.width || "auto"};
  ${(p) =>
    !p.allowSelect &&
    `-webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; -o-user-select: none; user-select: none; display: flex;
  `}
  ${(p) => p.fontFamily && `font-family: ${p.fontFamily};`};
`;

const Text = ({ data }) => {
  let { margin, text, characterLimit, ...rest } = data;

  // Apply character limit if specified
  if (characterLimit) {
    text = truncate(text, { length: characterLimit });
  }

  return (
    <StyledText {...rest} $margin={margin}>
      {text}
    </StyledText>
  );
};

export default Text;
